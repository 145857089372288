import styled from "styled-components";

export const Container = styled.div`
  background: #6d7582;
  width: 100%;
  height: 322px;
`;
export const Wrapper = styled.div`
  width: 1120px;
  margin: 0 auto;
`;

export const Logo = styled.div`
  img {
    width: 80px;
    height: 24px;
  }
  padding: 40px 0 32px;
`;

export const Comment = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: #bec3cc;
  height: 44px;

  padding-bottom: 20px;
  border-bottom: 1px solid #9ba1ac;
`;

export const CommentDetail = styled.div`
  padding: 20px 0;
  font-size: 16px;
  font-weight: 400;
  color: #bec3cc;
  height: 44px;
`;

export const CopyRight = styled.div`
  color: #bec3cc;
`;
