import React from "react";
import * as S from "./styles";

const index = ({ 빠른상담_신청 }) => {
  return (
    <S.Container>
      <S.TopArea>
        <div>
          <img src="/da_logo.png" alt="로고_이미지" />
        </div>
        <S.Comment>
          <div>다양한 비교, 하나로 편리하게!</div>
          <div>당신의 최상의 선택을 위한</div>
          <div>비교 서비스</div>
        </S.Comment>
        <S.Submit>
          <button onClick={빠른상담_신청}>빠른 상담신청</button>
        </S.Submit>
        <S.SubImg>
          <img src="/da_banner_subm.png" alt="베너_이미지" />
        </S.SubImg>
      </S.TopArea>
    </S.Container>
  );
};

export default index;
