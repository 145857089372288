import styled from "styled-components";

export const Container = styled.div``;
export const Items = styled.div`
  width: calc(100% - 40px);
  margin: 20px auto;
  height: 80px;

  display: flex;
  flex-wrap: wrap;
  border-radius: 12px;
  background: #e1f9fa;
  position: ${({ isFixed }) => (isFixed ? "fixed" : "relative")};
  top: 0;
  margin-left: ${({ isFixed }) => (isFixed ? "20px" : "")};
`;

export const Item = styled.div`
  width: 33%;
  text-align: center;
  line-height: 40px;
  background-color: ${(props) => (props.active ? "#04B4BB" : "#E1F9FA")};
  color: ${(props) => (props.active ? "#fff" : "#04B4BB")};
  border-radius: 12px;
  font-size: 16px;
  font-weight: 500;
`;

export const Wrapper = styled.div`
  margin: 0 auto;
  width: calc(100% - 40px);
  height: auto;
`;

export const Lists = styled.div`
  img {
    width: 100%;
    height: 136px;
    @media (max-width: 360px) {
      width: 130px;
      height: 136px;
    }
  }

  display: grid;

  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
`;
export const List = styled.div`
  padding-bottom: 20px;
  text-align: center;
  cursor: pointer;
`;

export const ListNotClick = styled.div`
  padding-bottom: 20px;
  text-align: center;
`;

export const Title = styled.div`
  padding: 14px 0 4px 0;
  color: #04b4bb;
  font-size: 16px;
  font-weight: 700;
`;

export const Detail = styled.div`
  font-size: 14px;
  font-weight: 400;
`;

export const LoanTitle = styled.div`
  font-size: 16px;
  font-weight: 700;
  padding-bottom: 20px;
`;

export const SelectButton = styled.button`
  width: 100%;
  padding: 0 10px;
  border: none;
  height: 44px;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  font-weight: 500;
  color: #6d7582;
  margin-bottom: 20px;
  img {
    width: 20px;
    height: 20px;
  }
`;
