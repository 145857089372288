import styled from "styled-components";

export const Intro = styled.div`
  img {
    height: 438px;
  }
`;

export const IntroComment = styled.div`
  text-align: center;
  padding: 60px 0 160px;
  line-height: 12px;
  font-size: 24px;
  font-weight: 400;
`;
export const IntroTitle = styled.div`
  text-align: center;
  padding-bottom: 60px;
`;

export const IntroWhy = styled.div`
  font-size: 60px;
  font-weight: 700;
`;
export const IntroWhyDetail = styled.div`
  font-size: 32px;
  font-weight: 700;
`;

export const IntroContent = styled.div`
  display: flex;
  justify-content: space-around;
  padding-bottom: 160px;
  img {
    width: 60px;
    height: 60px;
  }
`;

export const Content = styled.div`
  width: 33%;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
`;

export const ContentTitle = styled.div`
  padding: 40px 0 28px;
  font-size: 36px;
  font-weight: 700;
  color: #04b4bb;
`;
export const ContentCenter = styled.div`
  width: 33%;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  border-right: 1px solid #e6e8ed;
  border-left: 1px solid #e6e8ed;
  padding: 0 40px;
`;

export const FreeUse = styled.div`
  display: flex;
  text-align: center;
`;

export const FreeUseArrowDetail = styled.div`
  display: flex;
  text-align: center;
  padding: 20px 0 160px;
`;
export const FreeArrowWrapper = styled.div`
  display: flex;
  text-align: center;
  height: 50px;
  border-right: 1px dashed #000;
`;

export const ArrowFirst = styled.div`
  width: 50%;
  border-left: 1px dashed #000;
  border-right: 1px dashed #000;
  border-bottom: 10px solid #04b4bb;
`;
export const ArrowSecond = styled.div`
  position: relative;
  display: inline-block;
  width: 50%;
  top: 80%;
  margin-right: 10px;
  height: 10px;
  background-color: #bec3cc;

  /* 삼각형 모양을 만들기 위한 가상 요소 설정 */
  &::before {
    content: "";
    position: absolute;
    top: -190%;
    right: -2%;
    width: 0;
    height: 0;

    border-style: solid;
    border-width: 12px 0 12px 15px;
    border-color: transparent transparent transparent #bec3cc;
    transform: translateY(50%);
  }
`;

export const UseComment = styled.div`
  width: 50%;
  color: #6d7582;
`;

export const UseArrowDetailFirst = styled.div`
  width: 50%;
  color: #04b4bb;
  font-size: 36px;
  font-weight: 700;
`;
export const UseArrowDetailSecond = styled.div`
  width: 50%;
  color: #6d7582;
  font-size: 36px;
  font-weight: 700;
`;

export const IntroSubmitWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 60px;
`;

export const IntroSubmitLeft = styled.div`
  width: 55%;
  display: flex;
  align-items: center;
  gap: 34px;
`;

export const IntroSubmitRight = styled.div`
  width: 45%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const IntroSubmitButtonFirst = styled.div`
  width: 220px;
  height: 60px;
  display: flex;
  justify-content: center;
  background-color: #04b4bb;
  border-radius: 12px;
  color: #fff;
  align-items: center;
  font-size: 22px;
  font-weight: 700;
  cursor: pointer;
`;
export const IntroSubmitButtonSecond = styled.div`
  width: 220px;
  height: 60px;
  display: flex;
  background-color: #f9e000;
  border-radius: 12px;
  align-items: center;
  justify-content: center;
  gap: 12px;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;

  img {
    width: 32px;
    height: 32px;
  }
`;

export const IntroSubmitLeftComment = styled.div`
  font-size: 24px;
`;

export const IntroSubmitLeftPhone = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;

  img {
    width: 40px;
    height: 40px;
  }

  p {
    color: #04b4bb;
    font-size: 36px;
    font-weight: 700;
  }
`;

export const ContentTitleDetail = styled.div`
  color: #6d7582;
`;
