import React from "react";
import * as S from "./styles";

const index = ({ 빠른상담_신청 }) => {
  return (
    <S.Container>
      <S.TopArea>
        <S.Left>
          <S.Logo>
            <img src="/da_logo.png" alt="로고_이미지" />
          </S.Logo>
          <S.SubImg>
            <img src="/da_banner_sub.png" alt="베너_이미지" />
          </S.SubImg>
        </S.Left>
        <S.Right>
          <S.Text>
            다양한 비교, 하나로 편리하게!
            <br />
            당신의 최상의 선택을 위한 완벽한 비교 서비스
          </S.Text>

          <S.Wrapper>
            <S.Phone>
              <img src="/phone.png" alt="폰_이미지" />
              <span>1644-7407</span>
            </S.Phone>
            <button onClick={빠른상담_신청}>빠른 상담신청</button>
          </S.Wrapper>
        </S.Right>
      </S.TopArea>
    </S.Container>
  );
};

export default index;
