import styled from "styled-components";

export const Container = styled.div`
  background-color: #6d7582;
  width: 100%;
  height: 550px;
  padding-bottom: 40px;
`;
export const Wrapper = styled.div`
  width: calc(100% - 40px);
  margin: 0 auto;
  height: auto;
`;

export const Logo = styled.div`
  img {
    width: 80px;
    height: 24px;
  }
  padding: 40px 0 20px;
`;

export const Comment = styled.div`
  font-size: 16px;
  font-weight: 400;

  color: #bec3cc;
  display: flex;
  flex-direction: column;
  gap: 2px;
  word-break: keep-all;
  padding-bottom: 20px;
`;

export const CommentDetail = styled.div`
  font-size: 16px;
  font-weight: 400;

  color: #bec3cc;
  display: flex;
  flex-direction: column;
  gap: 2px;
  word-break: keep-all;
  padding-bottom: 20px;
  border-bottom: 1px solid #9ba1ac;
`;

export const FooterButton = styled.div`
  display: flex;
  color: #fff;
  gap: 12px;
  font-size: 16px;
  font-weight: 700;
  padding-bottom: 20px;
`;

export const CommentWarn = styled.div`
  padding-top: 20px;
  color: #bec3cc;
  font-size: 16px;
  font-weight: 400;
  word-break: keep-all;
  line-height: 22px;
`;
export const CopyRight = styled.div`
  color: #bec3cc;
  font-size: 16px;
  font-weight: 400;
  padding-top: 20px;
`;
