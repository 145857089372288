import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  background-image: url("/da_banner.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
`;
export const TopArea = styled.div`
  width: 1120px;
  height: 240px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
`;

export const Left = styled.div`
  padding-top: 20px;
  display: flex;
  flex-direction: column;
`;
export const Logo = styled.div`
  img {
    width: 132px;
    height: 40px;
  }
`;

export const SubImg = styled.div`
  img {
    width: 340px;
    height: 182px;
  }
`;

export const Right = styled.div`
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Text = styled.p`
  font-size: 28px;
  color: #fff;
  font-weight: 700;
  text-align: right;
  line-height: 36px;
  margin: 0;
`;

export const Phone = styled.div`
  img {
    width: 40px;
    height: 40px;
  }
  display: flex;
  align-items: center;
  gap: 10px;
  span {
    font-size: 36px;
    color: #00585b;
    font-weight: 700;
  }
`;

export const Wrapper = styled.div`
  display: flex;

  justify-content: space-between;
  button {
    width: 236px;
    height: 60px;
    background-color: #008388;
    border-radius: 12px;
    border: none;
    color: #fff;
    font-size: 22px;
    font-weight: 700;
  }
`;
