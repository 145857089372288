import React from "react";

import styled, { css } from "styled-components";

const Container = styled.div`
  height: 44px;
  margin: ${({ type }) => type === "margin" && "0 0 20px"};

  width: 100%;
  font-size: 18px;

  button {
    display: flex;

    align-items: center;
    justify-content: space-between;

    width: 100%;
    height: 44px;
    background-color: #fff;
    text-align: left;
    border: none;
    font-size: 18px;
    font-weight: 700;
    color: #eee;
    color: ${({ active }) => (active ? "#04b4bb" : "#6d7582")};
  }

  img {
    height: 25px;
  }
`;

const DrawerItem = ({ children, onClick, active, type }) => {
  return (
    <Container active={active} type={type}>
      <button onClick={onClick} type="button">
        <p>{children}</p>
        <img
          src={active ? "/check_active.png" : "/check_notactive.png"}
          alt="체크이미지"
        ></img>
      </button>
    </Container>
  );
};

export default DrawerItem;
