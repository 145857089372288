import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  background-image: url("/da_bannerm.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  height: 272px;
  overflow-x: hidden;
`;
export const TopArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: calc(100% - 40px);
  height: auto;
  overflow-x: hidden;
  margin: 0 auto;
  padding: 20px;
  img {
    width: 98px;
    height: 32px;
  }
  button {
    width: 160px;
    height: 60px;
    border-radius: 12px;
    border: none;
    background: #008388;
    font-size: 18px;
    font-weight: 700;
    color: #fff;
  }
`;

export const Comment = styled.div`
  color: #fff;
  display: flex;
  flex-direction: column;
  font-size: 20px;
  font-weight: 700;
`;

export const SubImg = styled.div`
  position: absolute;
  right: 0;
  top: 170px;
  padding-right: 20px;
  img {
    width: 128px;
    height: 100px;
  }
`;

export const Submit = styled.div`
  z-index: 1;
`;
