export const 탭_메뉴_TYPE = [
  { id: 1, title: "이사비교" },
  { id: 2, title: "인터넷" },
  { id: 3, title: "인테리어" },
  { id: 4, title: "대출비교" },
  // { id: 5, title: "카드비교" },
  // { id: 6, title: "핸드폰" },
  { id: 5, title: "렌트/리스" },
  { id: 6, title: "생활가전렌탈" },
];

export const 대출비교_탭_메뉴_TYPE = [
  { id: 1, title: "플랫폼" },
  { id: 2, title: "은행권" },
  { id: 3, title: "보험사" },
  { id: 4, title: "캐피탈" },
  { id: 5, title: "저축은행" },
  { id: 6, title: "P2P대출" },
  { id: 7, title: "서민금융 대부" },
  { id: 8, title: "상담사" },
];

export const 이사_비교_TYPE = [
  {
    id: 1,
    title: "영구크린",
    site: "http://www.mcygclean.com",
    img: "/move/영구크린.png",
    subTitle: ["32만 고객의 선택!", "이사비교견적 서비스"],
  },
  {
    id: 2,
    title: "이사몰",
    img: "/move/이사몰.png",
    site: "http://www.2424.net",
    subTitle: ["비교할수록 똑똑한 이사!", "포장이사 가격비교"],
  },
  {
    id: 3,
    title: "24번가",
    img: "/move/24번가.png",
    site: "http://24st.co.kr",
    subTitle: ["비교할수록 가성비 최고!", "합리적인 포장이사 가격"],
  },
  {
    id: 4,
    title: "바로24",
    img: "/move/바로24.png",
    site: "https://baro24.net/",
    subTitle: ["지역별 관허 인증업체", "무료로 견적비교하기!"],
  },
  {
    id: 5,
    title: "이사플러스",
    img: "/move/이사플러스.png",
    site: "http://www.24dr.net",
    subTitle: ["합리적인 비용, 무료 방문견적", "이사플러스"],
  },
  {
    id: 6,
    title: "24몰",
    img: "/move/24몰.png",
    site: "http://www.24mall.co.kr",
    subTitle: ["이사비용은 DOWN", "서비스는 UP"],
  },
  {
    id: 7,
    title: "이사스토리",
    img: "/move/이사스토리.png",
    site: "https://24story.co.kr/",
    subTitle: ["최고 20%까지 절약 가능한", "이사스토리 비교견적"],
  },
  {
    id: 8,
    title: "홍반장이사",
    img: "/move/홍반장이사.png",
    site: "http://홍반장이사.com",
    subTitle: ["토탈 홈 서비스", "전국 포장이사 전문"],
  },
  {
    id: 9,
    title: "좋은이사",
    img: "/move/좋은이사.png",
    site: "http://www.24good.kr",
    subTitle: ["거품없는 가격으로", "친절을 약속드립니다!"],
  },
  {
    id: 10,
    title: "드림하이이사",
    img: "/move/드림하이이사.png",
    site: "https://dreamhi24.com",
    subTitle: ["20년이상 경력 전문가의", "꼼꼼한 이사"],
  },
  {
    id: 11,
    title: "이사타운",
    img: "/move/이사타운.png",
    site: "http://movingtown.co.kr",
    subTitle: ["물류이사 분야의 성공을 위한", "최고의 파트너"],
  },
  {
    id: 12,
    title: "미소",
    img: "/move/미소.png",
    site: "https://miso.kr",
    subTitle: ["이사는 복잡하지 않아요,", "미소만 있으면"],
  },
  {
    id: 13,
    title: "오렌지라이프",
    img: "/move/오렌지라이프.png",
    site: "http://olife.kr/",
    subTitle: ["장기렌트리스, 인터넷,", "이사 비교견적 무료상담"],
  },
  {
    id: 14,
    title: "파란이사",
    img: "/move/파란이사.png",
    site: "http://www.paran24.co.kr",
    subTitle: ["소비자 만족도 1위", "걱정은 싹! 마음엔 쏙!"],
  },
  {
    id: 15,
    title: "다방이사",
    img: "/move/다방이사.png",
    site: "http://dabangcare.com",
    subTitle: ["소중한 이사날의 의미와", "함께합니다"],
  },
  {
    id: 16,
    title: "이사일번지",
    img: "/move/이사일번지.png",
    site: "http://www.2401.co.kr",
    subTitle: ["행복까지 배달하는", "안전하고 신속한 운송"],
  },
  {
    id: 17,
    title: "하나로24",
    img: "/move/하나로24.png",
    site: "https://hanaro24.com",
    subTitle: ["오랜 경력의", "이사전문업체"],
  },
  {
    id: 18,
    title: "사계절이사",
    img: "/move/사계절이사.png",
    site: "https://사계절이사.com",
    subTitle: ["저렴한 이사비용", "실시간 무료견적비교"],
  },
  {
    id: 19,
    title: "예스이사몰",
    img: "/move/예스이사몰.png",
    site: "http://www.yes24mall.co.kr",
    subTitle: ["편안하고 안전한", "포장이사 서비스 제공"],
  },
  {
    id: 20,
    title: "포장이사 달인",
    img: "/move/포장이사달인.png",
    site: "http://www.1666-2423.com/",
    subTitle: ["이사의 새로운 패러다임을", "제시하는 젋은 기업"],
  },
];

export const 인터넷_비교_TYPE = [
  {
    id: 1,
    title: "인터넷민족",
    site: "http://www.mcygclean.com",
    img: "/internet/인터넷민족.png",
    subTitle: ["대한민국 NO.1", "인터넷가입 공식대리점"],
  },
  {
    id: 2,

    title: "통신직구",
    img: "/internet/통신직구.png",
    site: "https://www.gik9.kr/",
    subTitle: ["현금조건 상상불가", "유통거품 없는 직거래"],
  },
  {
    id: 3,
    title: "백메가",
    img: "/internet/백메가.png",
    site: "https://www.100mb.kr/",
    subTitle: ["이용자가 선택한", "우수 콘텐츠서비스"],
  },
  {
    id: 4,
    title: "서경석의 인터넷비교원",
    img: "/internet/서경석의인터넷비교원.png",
    site: "https://www.megapass-skbroadband-powercomm.com/",
    subTitle: ["변함없는 최대 혜택을", "제공합니다"],
  },
  {
    id: 5,
    title: "기가요",
    img: "/internet/기가요.png",
    site: "https://gigayo.net/",
    subTitle: ["현금사은품 33만원", "당일지급"],
  },
  {
    id: 6,
    title: "국가대표통신",
    img: "/internet/국가대표통신.png",
    site: "https://korea-it.co.kr/",
    subTitle: ["전국 최고 지원금을", "약속드립니다"],
  },
  {
    id: 7,
    title: "비타민인터넷",
    img: "/internet/비타민인터넷.png",
    site: "http://비타민인터넷.kr",
    subTitle: ["인터넷+티비 지금 가입시", "선물이 펑펑펑"],
  },
  {
    id: 8,
    title: "오만원더통신",
    img: "/internet/오만원더통신.png",
    site: "http://오만원더통신.com",
    subTitle: ["요금저렴+사은품많이", "당일지급"],
  },
  {
    id: 9,
    title: "웰컴컴퍼니",
    img: "/internet/웰컴컴퍼니.png",
    site: "http://www.웰컴컴퍼니.com/",
    subTitle: ["프리미엄 혜택과", "가치를 더하다!"],
  },
  {
    id: 10,
    title: "통신의성지",
    img: "/internet/통신의성지.png",
    site: "http://www.통신의성지.com",
    subTitle: ["통신의 성지는", "고객님만 생각합니다"],
  },
  {
    id: 11,
    title: "고마워아이티",
    img: "/internet/고마워아이티.png",
    site: "https://thankyouit.co.kr",
    subTitle: ["깜짝놀랄 지원금", "고객만족 200%"],
  },
  {
    id: 12,
    title: "기가인터넷",
    img: "/internet/기가인터넷.png",
    site: "https://www.giga-internet.co.kr/",
    subTitle: ["인터넷가입 본사 승인", "공식 대리점"],
  },
  {
    id: 13,
    title: "인터넷프렌즈",
    img: "/internet/인터넷프렌즈.png",
    site: "https://www.internetfriends.co.kr/",
    subTitle: ["인터넷프렌즈에서는", "200만원 최대 혜택"],
  },
  {
    id: 14,
    title: "인터넷마스터",
    img: "/internet/인터넷마스터.png",
    site: "https://인터넷마스터.com",
    subTitle: ["같은상품 요금이면", "현금지원 많이 받는게 최고!"],
  },
  {
    id: 15,
    title: "통신챔피언",
    img: "/internet/통신챔피언.png",
    site: "https://www.cable-inter.com/",
    subTitle: ["전국인터넷", "가격비교 사이트"],
  },
  {
    id: 16,
    title: "인터넷성지",
    img: "/internet/인터넷성지.png",
    site: "http://internetsj.com/",
    subTitle: ["저희는 진짜 먹고 살", "만큼만 남기겠습니다!"],
  },
  {
    id: 17,
    title: "브로드앤라인",
    img: "/internet/브로드앤라인.png",
    site: "http://www.broadnline.co.kr",
    subTitle: ["SK인터넷가입", "NO.1 브로드앤라인"],
  },
  {
    id: 18,
    title: "푸른라인",
    img: "/internet/푸른라인.png",
    site: "http://www.purunline.co.kr",
    subTitle: ["제대로 된 인터넷 가격,", "혜택 비교!"],
  },
  {
    id: 19,
    title: "통신파더",
    img: "/internet/통신파더.png",
    site: "https://www.cable-sale.com",
    subTitle: ["Since 2000", "통신 23년차 전문가 마켓"],
  },
  {
    id: 20,
    title: "오픈딜",
    img: "/internet/오픈딜.png",
    site: "https://open-deal.co.kr/",
    subTitle: ["인터넷 가입은", "쉽고 편안한 오픈딜!"],
  },
];

export const 인테리어_비교_TYPE = [
  {
    id: 1,
    title: "하다",
    site: " https://ms-housing.kr/",
    img: "/interior/하다.png",
    subTitle: ["전국 무료 방문", "비교견적"],
  },
  {
    id: 2,
    title: "오늘의집",
    site: "https://ohou.se/experts",
    img: "/interior/오늘의집.png",
    subTitle: ["시공책임보장으로", "완전보장"],
  },
  {
    id: 3,
    title: "공간의힘",
    site: "http://www.gonghim.com",
    img: "/interior/공간의힘.png",
    subTitle: ["인테리어 고민이라면", "공간의힘"],
  },
  {
    id: 4,
    title: "집닥",
    site: "https://zipdoc.co.kr/",
    img: "/interior/집닥.png",
    subTitle: ["집닥과 함께라면", "3년동안 A/S 해드려요"],
  },
  {
    id: 5,
    title: "한샘 리하우스",
    site: "https://remodeling.hanssem.com/",
    img: "/interior/한샘리하우스.png",
    subTitle: ["인테리어 신년혜택", "풍성한 혜택이 가득"],
  },
  {
    id: 6,
    title: "동거동락",
    site: "http://www.dgdr.co.kr",
    img: "/interior/동거동락.png",
    subTitle: ["1인가구에 최적화된", "쾌적한 인테리어"],
  },
  {
    id: 7,
    title: "인테리어베이",
    site: "https://www.interiorbay.co.kr/",
    img: "/interior/인테리어베이.png",
    subTitle: ["라이프스타일에 맞는", "인테리어 전문가 ㅅ아담"],
  },
  {
    id: 8,
    title: "한솔봄",
    site: "https://www.vhom.co.kr/",
    img: "/interior/한솔봄.png",
    subTitle: ["한솔그룹이 만든", "인테리어 브랜드"],
  },
  {
    id: 9,
    title: "큐플레이스",
    site: "https://www.qplace.kr/",
    img: "/interior/큐플레이스.png",
    subTitle: ["상업공간 인테리어", "비교견적 서비스"],
  },
];

export const 렌트리스_비교_TYPE = [
  {
    id: 1,
    title: "스윗코",
    site: "https://www.sweetco.co.kr/",
    img: "/rentlease/스윗코.png",
    subTitle: ["신차 장기렌터카", "무조건 최저가"],
  },
  {
    id: 2,
    title: "아마존카",
    site: "https://www.amazoncar.co.kr/",
    img: "/rentlease/아마존카.png",
    subTitle: ["로그인 없이", "실시간 셀프견적 가능"],
  },
  {
    id: 3,
    title: "렌트24",
    site: "https://rent-24.co.kr/",
    img: "/rentlease/렌트24.png",
    subTitle: ["자동차에", "생각을 담다"],
  },
  {
    id: 4,
    title: "카빌리지",
    site: "http://carvillage.co.kr/",
    img: "/rentlease/카빌리지.png",
    subTitle: ["신차장기렌터카", "카빌리지"],
  },
  {
    id: 5,
    title: "오토클래스",
    site: "http://rent.autoclass.co.kr/",
    img: "/rentlease/오토클래스.png",
    subTitle: ["지금껏 찾고 있던", "가장 저렴한 장기렌트"],
  },
  {
    id: 6,
    title: "카핑",
    site: "https://carping1.com/",
    img: "/rentlease/카핑.png",
    subTitle: ["원하는 차를 맘껏", "골라 쇼핑하는 카핑"],
  },
  {
    id: 7,
    title: "사이렌카",
    site: "https://sirencar.com/",
    img: "/rentlease/사이렌카.png",
    subTitle: ["특판프로모션 차량", "대량 확보"],
  },
  {
    id: 8,
    title: "굿모닝장기렌트",
    site: "http://rent-good.co.kr/",
    img: "/rentlease/굿모닝장기렌트.png",
    subTitle: ["초특가를", "뛰어넘다"],
  },
  {
    id: 9,
    title: "다타랩",
    site: "http://www.data-gnarentcar.com/",
    img: "/rentlease/다타랩.png",
    subTitle: ["다타랩에서만 가능한", "단독할인"],
  },
  {
    id: 10,
    title: "장기렌트다모아",
    site: "https://damoa-rent.com/",
    img: "/rentlease/장기렌트다모아.png",
    subTitle: ["다모아가 드리는 첫", "할인혜택 받으세용"],
  },
  {
    id: 11,
    title: "KS오토플랜",
    site: "http://www.ks-rentcar.com/",
    img: "/rentlease/KS오토플랜.png",
    subTitle: ["신차장기렌트", "신차리스"],
  },
  {
    id: 12,
    title: "픽카",
    site: "https://shop.pickcar.kr/",
    img: "/rentlease/픽카.png",
    subTitle: ["맞춤형 신차", "장기렌터카 프로그램"],
  },
  {
    id: 13,
    title: "원픽",
    site: "https://one-pick.kr/",
    img: "/rentlease/원픽.png",
    subTitle: ["꼭! 필요한 분들을 위한", "합리적인 방법"],
  },
  {
    id: 14,
    title: "카베이",
    site: "https://www.carbay.co.kr/",
    img: "/rentlease/카베이.png",
    subTitle: ["장기렌트/리스", "최저가는 따로 있다!"],
  },
  {
    id: 15,
    title: "에이원오토",
    site: "https://a1auto.co.kr/",
    img: "/rentlease/에이원오토.png",
    subTitle: ["합리적인", "차량구매시스템"],
  },
  {
    id: 16,
    title: "오케이장기렌트",
    site: "http://okay-rent.co.kr/",
    img: "/rentlease/오케이장기렌트.png",
    subTitle: ["지금 계약하면 대기 없이", "7일 내 즉시출고"],
  },
  {
    id: 17,
    title: "렌트앤카",
    site: "https://rentncar.com/",
    img: "/rentlease/렌트앤카.png",
    subTitle: ["장기렌트 리스", "가격비교 전문기업"],
  },
  {
    id: 18,
    title: "헬로오토플랜",
    site: "http://www.helloautoplan.com/",
    img: "/rentlease/헬로오토플랜.png",
    subTitle: ["신차 할부보다 저렴한", "장기렌트카"],
  },
  {
    id: 19,
    title: "카앤",
    site: "http://car-n.co.kr/",
    img: "/rentlease/카앤.png",
    subTitle: ["최저가 기준", "맞춤형 상담"],
  },
  {
    id: 20,
    title: "케이다이렉트카",
    site: "https://www.direct-car.kr/",
    img: "/rentlease/케이다이렉트카.png",
    subTitle: ["장기렌트카 비용과 가격을", "한눈에 비교"],
  },
];

export const 생활가전렌탈_비교_TYPE = [
  {
    id: 1,
    title: "렌트리",
    site: "https://rentre.kr/",
    img: "/rental/렌트리.png",
    subTitle: ["월렌탈료와 지원금 헤택", "대신 찾아드려요"],
  },
  {
    id: 2,
    title: "아정당",
    site: "https://www.ajungrental.com/",
    img: "/rental/아정당.png",
    subTitle: ["빠르고 간편한", "렌탈 상담신청"],
  },
  {
    id: 3,
    title: "렌탈샵",
    site: "https://shop-care.shop/",
    img: "/rental/렌탈샵.png",
    subTitle: ["제휴카드 사용시", "렌탈료 할인"],
  },
  {
    id: 4,
    title: "이니렌탈",
    site: "https://direct-rental.net/",
    img: "/rental/이니렌탈.png",
    subTitle: ["신개념 간편렌탈 서비스", "이니렌탈"],
  },
  {
    id: 5,
    title: "빌리다렌탈",
    site: "https://xn--2j1bp2kq6d.com/",
    img: "/rental/빌리다렌탈.png",
    subTitle: ["가전렌탈 종합설계", "고객 맞춤형으로!"],
  },
  {
    id: 6,
    title: "일렉트로렌탈",
    site: "http://www.electrorental.co.kr/",
    img: "/rental/일렉트로렌탈.png",
    subTitle: ["모든 가전을 부담없이", "장만하는 렌탈의 행복"],
  },
  {
    id: 7,
    title: "LG헬로렌탈",
    site: "https://ace-rental.net/",
    img: "/rental/LG헬로렌탈.png",
    subTitle: ["신세계 상품권", "증정 이벤트"],
  },
  {
    id: 8,
    title: "렌탈스토리",
    site: "https://rentalstory.kr/",
    img: "/rental/렌탈스토리.png",
    subTitle: ["빠르고 간편한", "렌탈 상담신청"],
  },
  {
    id: 9,
    title: "OK렌탈",
    site: "https://ok-rental.shop/",
    img: "/rental/OK렌탈.png",
    subTitle: ["빠르고 간편한", "렌탈 상담신청"],
  },
  {
    id: 10,
    title: "렌탈의중심",
    site: "https://rentalcenter.kr/",
    img: "/rental/렌탈의중심.png",
    subTitle: ["저신용가전렌탈,", "렌탈의중심"],
  },
  {
    id: 11,
    title: "피오렌탈",
    site: "https://porental.kr/",
    img: "/rental/피오렌탈.png",
    subTitle: ["국내최초", "VR렌탈"],
  },
  {
    id: 12,
    title: "아띠렌탈",
    site: "https://www.xn--vh2bumn14a8gl.com/",
    img: "/rental/아띠렌탈.png",
    subTitle: ["빠르고 간편한", "렌탈 상담신청"],
  },
  {
    id: 13,
    title: "대승렌탈",
    site: "http://xn--vk1bt9giykmto.com/",
    img: "/rental/대승렌탈.png",
    subTitle: ["이런 혜택", "어디에도 없다!"],
  },
  {
    id: 14,
    title: "렌탈아지트",
    site: "https://rentalagit.com/",
    img: "/rental/렌탈아지트.png",
    subTitle: ["특별한 혜택", "패키지 할인"],
  },
  {
    id: 15,
    title: "렌탈톡",
    site: "https://www.rentaltok.com/",
    img: "/rental/렌탈톡.png",
    subTitle: ["매월 1만원~3만원", "렌탈요금 할인"],
  },
  {
    id: 16,
    title: "렌탈세일",
    site: "https://rentalsail.com/",
    img: "/rental/렌탈세일.png",
    subTitle: ["빠르고 간편한", "렌탈 상담신청"],
  },
  {
    id: 17,
    title: "착한렌탈",
    site: "https://kind-rental.com/",
    img: "/rental/착한렌탈.png",
    subTitle: ["착한가격, 착한혜택", "모든 렌탈을 담다"],
  },
  {
    id: 18,
    title: "한눈에",
    site: "https://atalook.co.kr/",
    img: "/rental/한눈에.png",
    subTitle: ["렌탈 최저가", "비교견적 플랫폼"],
  },
  {
    id: 19,
    title: "렌탈컬리",
    site: "https://rental-kurly.com/",
    img: "/rental/렌탈컬리.png",
    subTitle: ["모든 렌탈을 한번에", "가격비교! 최저가 설계!"],
  },
  {
    id: 20,
    title: "렌탈스테이",
    site: "https://stay.rental-shop.net/",
    img: "/rental/렌탈스테이.png",
    subTitle: ["모든 가전을 부담없이", "장만하는 렌탈의 행복"],
  },
];

export const 플랫폼_TYPE = [
  {
    id: 1,
    title: "뱅크몰",
    site: "https://www.bank-mall.co.kr",
    img: "/platform/뱅크몰.png",
    subTitle: ["197개 금융사 비교로", "더 정확하게!"],
  },
  {
    id: 2,
    title: "네이버페이",
    site: "https://www.naverfincorp.com/",
    img: "/platform/네이버페이.png",
    subTitle: ["금융을 넓히는 기술,", "네이버페이"],
  },
  {
    id: 3,
    title: "카카오페이",
    site: "https://www.kakaopay.com/",
    img: "/platform/카카오페이.png",
    subTitle: ["마음놓고", "금융하다"],
  },
  {
    id: 4,
    title: "토스",
    site: "https://toss.im/",
    img: "/platform/토스.png",
    subTitle: ["금융의 모든 것", "토스에서 쉽고 간편하게"],
  },
  {
    id: 5,
    title: "핀다",
    site: "https://finda.co.kr/",
    img: "/platform/핀다.png",
    subTitle: ["핀다에서는", "한번에, 손쉽게!"],
  },
  {
    id: 6,
    title: "담비",
    site: "https://www.dambee.com/",
    img: "/platform/담비.png",
    subTitle: ["더 가까운 대출 전문가", "담비로 찾다"],
  },
  {
    id: 7,
    title: "페이코",
    site: "https://www.payco.com/",
    img: "/platform/페이코.png",
    subTitle: ["생활 속 어디서나", "페이코"],
  },
  {
    id: 8,
    title: "뱅크샐러드",
    site: "https://www.banksalad.com/",
    img: "/platform/뱅크샐러드.png",
    subTitle: ["뱅샐에서 대출받으면", "금리 0.1%할인"],
  },
  {
    id: 9,
    title: "에이피더핀",
    site: "https://www.apthefin.com/",
    img: "/platform/에이피더핀.png",
    subTitle: ["더 친절하고 더 편리한", "금융의 고유명사"],
  },
  {
    id: 10,
    title: "알다",
    site: "https://www.alda.ai/teamwink",
    img: "/platform/알다.png",
    subTitle: ["눈탱이 맞지 않는", "금융 세상을 만듭니다"],
  },
  {
    id: 11,
    title: "핀크",
    site: "https://www.finnq.com/",
    img: "/platform/핀크.png",
    subTitle: ["전문상담사가 친절한", "상담을 해드립니다"],
  },
];

export const 은행권_TYPE = [
  {
    id: 1,
    title: "국민은행",
    site: "https://obank.kbstar.com/quics?page=C103557#loading",
    img: "/bank/국민은행.png",
    subTitle: ["예부적금담보대출", "아낌e보금자리론"],
  },
  {
    id: 2,
    title: "우리은행",
    site: "https://spot.wooribank.com/pot/Dream?withyou=POLON0056",
    img: "/bank/우리은행.png",
    subTitle: ["우리WON주택대출", "우리아파트론"],
  },
  {
    id: 3,
    title: "신한은행",
    site: "https://bank.shinhan.com/index.jsp#020305010000",
    img: "/bank/신한은행.png",
    subTitle: ["아낌e보금자리론", "버팀목전세자금대출"],
  },
  {
    id: 4,
    title: "하나은행",
    site: "https://www.kebhana.com/cont/mall/mall08/mall0802/mall080202/1420297_115196.jsp",
    img: "/bank/하나은행.png",
    subTitle: ["복잡한 주택담보대출", "원클릭으로 간편하게"],
  },
  {
    id: 5,
    title: "농협은행",
    site: "https://www.nonghyup.com/introduce/interest/loan2.do",
    img: "/bank/농협은행.png",
    subTitle: ["담보대출", "금리현황 서비스"],
  },
  {
    id: 6,
    title: "카카오뱅크",
    site: "https://www.kakaobank.com/products/mortgageLoan",
    img: "/bank/카카오뱅크.png",
    subTitle: ["챗봇과 함께", "쉽고 편리하게"],
  },
  {
    id: 7,
    title: "케이뱅크",
    site: "https://www.kbanknow.com/ib20/mnu/FPMLON250000",
    img: "/bank/케이뱅크.png",
    subTitle: ["구입반환생활자금", "기존 대출 갈아타기까지"],
  },
  {
    id: 8,
    title: "IBK기업은행",
    site: "https://mybank.ibk.co.kr/uib/jsp/guest/ntr/ntr70/ntr7015/PNTR701500_i2.jsp?MENU_DIV=GNB&grcd=21&lncd=02&tmcd=201&pdcd=0144",
    img: "/bank/IBK기업은행.png",
    subTitle: ["금리,기간,상환방법 선택", "IBK주택담보대출"],
  },
  {
    id: 9,
    title: "대구은행",
    site: "https://www.dgb.co.kr/cms/event/2023/event_houseloan_m.html?link=app",
    img: "/bank/대구은행.png",
    subTitle: ["영업정 방문없이", "한번에!"],
  },
  {
    id: 10,
    title: "전북은행",
    site: "https://www.jbbank.co.kr/loan_hous_mrtg.act",
    img: "/bank/전북은행.png",
    subTitle: ["COFIX 또는", "CD수익률 연동"],
  },
  {
    id: 11,
    title: "SC제일은행",
    site: "https://www.standardchartered.co.kr/np/kr/cm/cc/AnnonceList.jsp?PUB_NTC_PRDCT_TYPE_CD=B001&menuId=HT00050301000000&rnb=3&rnbStep=7",
    img: "/bank/SC제일은행.png",
    subTitle: ["금융상품을 이해하고", "선택하는 유용한 정보"],
  },
  {
    id: 12,
    title: "광주은행",
    site: "https://www.kjbank.com/ib20/mnu/BHPCSCN040301",
    img: "/bank/광주은행.png",
    subTitle: ["변경일자에 따른 설명서,", "특약, 변경내역 확인"],
  },
  {
    id: 13,
    title: "토스뱅크",
    site: "https://www.tossbank.com/product-service/loans/loan",
    img: "/bank/토스뱅크.png",
    subTitle: ["돈이 필요한 순간", "토스뱅크 신용대출"],
  },
  {
    id: 14,
    title: "수협은행",
    site: "https://www.suhyup-bank.com/",
    img: "/bank/수협은행.png",
    subTitle: ["Sh수협은행만의", "주택자금대출"],
  },
  {
    id: 15,
    title: "제주은행",
    site: "https://www.e-jejubank.com/JeJuBankInfo.do",
    img: "/bank/제주은행.png",
    subTitle: ["비상시에 빠르고 간편하게", "비상금 대출"],
  },
  {
    id: 16,
    title: "경남은행",
    site: "https://www.knbank.co.kr/ib20/mnu/FPMLON050103000",
    img: "/bank/경남은행.png",
    subTitle: ["BNK경남은행", "주택담보대출"],
  },
  {
    id: 17,
    title: "부산은행",
    site: "https://www.busanbank.co.kr/ib20/mnu/FPMLON092008001",
    img: "/bank/부산은행.png",
    subTitle: ["BNK부산은행", "주택담보대출"],
  },
];

export const 보험사_TYPE = [
  {
    id: 1,
    title: "교보생명",
    site: "https://www.kyobo.com/dgt/web/loan/product/house/security/m-general-security-text-detail",
    img: "/insurance/교보생명.png",
    subTitle: ["아파트 이외의", "부동산을 담보로"],
  },
  {
    id: 2,
    title: "삼성생명",
    site: "https://www.samsunglife.com/individual/products/loan/detail/F34820",
    img: "/insurance/삼성생명.png",
    subTitle: ["다양한 상품 설명부터", "상황, 목적에 맞는 상품 추천"],
  },
  {
    id: 3,
    title: "삼성화재",
    site: "https://www.samsungfire.com/loan/P_P04_04_01_066.html",
    img: "/insurance/삼성화재.png",
    subTitle: ["대출한도와 금리를", "쉽고 간편하게"],
  },
  {
    id: 4,
    title: "푸본현대생명",
    site: "https://www.fubonhyundai.com/#FP010000000000",
    img: "/insurance/푸본현대생명.png",
    subTitle: ["담보대출", "금리현황 서비스"],
  },
  {
    id: 5,
    title: "하나생명",
    site: "https://www.hanalife.co.kr/prd/personal/apart/apartLoanEtc.do",
    img: "/insurance/하나생명.png",
    subTitle: ["최대한도 10억원", "대출기간 최대 40년"],
  },
  {
    id: 6,
    title: "한화생명",
    site: "https://m.hanwhalife.com/mobile/finance/loan/FN_LNTGD000_P20000.do?catecode=LD020100",
    img: "/insurance/한화생명.png",
    subTitle: ["내 집 마련의 꿈", "주택담보대출"],
  },
  {
    id: 7,
    title: "현대해상",
    site: "https://www.hi.co.kr/serviceAction.do",
    img: "/insurance/현대해상.png",
    subTitle: ["현대해상", "아파트담보대출 오픈"],
  },
  {
    id: 8,
    title: "흥국생명",
    site: "https://www.heungkuklife.co.kr/front/loan/loanProductInfo.do?cd_prt=LA2011",
    img: "/insurance/흥국생명.png",
    subTitle: ["고객님이 꿈구는 행복", "흥국과 만들어 가세요"],
  },
  {
    id: 9,
    title: "KB손해보험",
    site: "https://www.kbinsure.co.kr/CG408020001.ec",
    img: "/insurance/KB손해보험.png",
    subTitle: ["전문상담사가", "친절한 상담을 해드립니다"],
  },
];

export const 캐피탈_TYPE = [
  {
    id: 1,
    title: "농협캐피탈",
    site: "https://www.nhcapital.co.kr/",
    img: "/capital/농협캐피탈.png",
    subTitle: ["대출이 필요할때", "안전하고 든든한 파트너"],
  },
  {
    id: 2,
    title: "롯데캐피탈",
    site: "https://www.lottecap.com/ma/ma/MAMA00100",
    img: "/capital/롯데캐피탈.png",
    subTitle: ["신용대출, 자동차할부", "사업운영자금"],
  },
  {
    id: 3,
    title: "우리금융캐피탈",
    site: "https://www.woorifcapital.com/portal/portalMain.do",
    img: "/capital/우리금융캐피탈.png",
    subTitle: ["오늘의 혁신으로", "내일의 가치를"],
  },
  {
    id: 4,
    title: "웰컴캐피탈",
    site: "https://www.welcomecapital.com/",
    img: "/capital/웰컴캐피탈.png",
    subTitle: ["고객의 힘이 되는", "웰컴캐피탈"],
  },
  {
    id: 5,
    title: "하나캐피탈",
    site: "https://www.hanacapital.co.kr/",
    img: "/capital/하나캐피탈.png",
    subTitle: ["무담보 무설정", "아파트 소유자 신용대출"],
  },
  {
    id: 6,
    title: "한국투자캐피탈",
    site: "https://efis.kicf.co.kr/",
    img: "/capital/한국투자캐피탈.png",
    subTitle: ["서류제출 없이", "대출신청, 송금 한번에"],
  },
  {
    id: 7,
    title: "현대캐피탈",
    site: "https://www.hyundaicapital.com/seln/hcln/CPSLHC0101.hc",
    img: "/capital/현대캐피탈.png",
    subTitle: ["시세의 최대", "85%까지"],
  },
  {
    id: 8,
    title: "DGB캐피탈",
    site: "https://www.nhcapital.co.kr/",
    img: "/capital/DGB캐피탈.png",
    subTitle: ["똑똑하고 친절한", "개인금융"],
  },
  {
    id: 9,
    title: "JB우리캐피탈",
    site: "https://www.wooricap.com/fin/ret/JFINRET0001.do",
    img: "/capital/JB우리캐피탈.png",
    subTitle: ["JB우리캐피탈", "주택담보대출"],
  },
];

export const 저축은행_TYPE = [
  {
    id: 1,
    title: "다올저축은행",
    site: "https://www.daolsb.com/loan/loanGoods.do?gds_cd=LC111",
    img: "/savingbank/다올저축은행.png",
    subTitle: ["아파트를 담보로", "대출받을 수 있는 상품"],
  },
  {
    id: 2,
    title: "대신저축은행",
    site: "https://bank.daishin.com/",
    img: "/savingbank/대신저축은행.png",
    subTitle: ["저축은행 최초", "지문인증시스템"],
  },
  {
    id: 3,
    title: "민국저축은행",
    site: "https://www.mkb.co.kr/LonInfoInfo0100.act",
    img: "/savingbank/민국저축은행.png",
    subTitle: ["예적금담보대출", "(인터넷뱅킹)"],
  },
  {
    id: 4,
    title: "바로저축은행",
    site: "https://www.barosavings.com/ProdList_001.act?rnum=22",
    img: "/savingbank/바로저축은행.png",
    subTitle: ["바로저축은행", "부동산담보대출"],
  },
  {
    id: 5,
    title: "신한저축은행",
    site: "https://www.shinhansavings.com/CM_0004",
    img: "/savingbank/신한저축은행.png",
    subTitle: ["신한저축은행의", "추천상품을 만나보세요"],
  },
  {
    id: 6,
    title: "애큐온저축은행",
    site: "https://www.acuonsb.co.kr/",
    img: "/savingbank/애큐온저축은행.png",
    subTitle: ["새롭게 바뀐", "애큐온 저축은행 앱"],
  },
  {
    id: 7,
    title: "예가람저축은행",
    site: "https://www.yegaramsb.co.kr/disclosure/loan_intro",
    img: "/savingbank/예가람저축은행.png",
    subTitle: ["큰 빛으로 밝히는", "예가람저축은행"],
  },
  {
    id: 8,
    title: "웰컴저축은행",
    site: "https://www.welcomebank.co.kr/ib20/mnu/IBNLCTLOR005",
    img: "/savingbank/웰컴저축은행.png",
    subTitle: ["웰컴디지털뱅크", "담보대출의 틀을 깨다!"],
  },
  {
    id: 9,
    title: "유안타저축은행",
    site: "https://www.yuantasavings.co.kr/ProdList_001.act?rnum=31",
    img: "/savingbank/유안타저축은행.png",
    subTitle: ["고객의", "믿음직한 파트너"],
  },
  {
    id: 10,
    title: "JT친애저축은행",
    site: "https://www.jtchinae-bank.co.kr/",
    img: "/savingbank/JT친애저축은행.png",
    subTitle: ["든든하게 지켜주는", "친구처럼"],
  },
  {
    id: 11,
    title: "조은저축은행",
    site: "https://www.choeunbank.com/kor/loan/apt.asp#snb_w",
    img: "/savingbank/조은저축은행.png",
    subTitle: ["고객과의 신뢰를", "소중히 여기는 은행"],
  },
  {
    id: 12,
    title: "키움YES저축은행",
    site: "https://www.kiwoomyesbank.com/ProdList_001.act?rnum=70",
    img: "/savingbank/키움YES저축은행.png",
    subTitle: ["키움YES", "부동산 담보대출"],
  },
  {
    id: 13,
    title: "푸른저축은행",
    site: "https://www.prsb.co.kr/prsb/prwww.html?w2xPath=/w2/itb/main.xml&menu=3&menuNo=320&menuNo=320",
    img: "/savingbank/푸른저축은행.png",
    subTitle: ["아파트를 담보로", "신속하게 자금대출"],
  },
  {
    id: 14,
    title: "하나저축은행",
    site: "https://www.kebhana.com/cont/mall/mall08/mall0805/index.jsp?catId=spb_2821,spb_2822,spb_2823,spb_2824,spb_2825,spb_2826&_menuNo=98786",
    img: "/savingbank/하나저축은행.png",
    subTitle: ["신청에서 입금까지", "24시간 365일 가능"],
  },
  {
    id: 15,
    title: "한국투자저축은행",
    site: "https://sb.koreainvestment.com/?PRD-LON001-1#",
    img: "/savingbank/한국투자저축은행.png",
    subTitle: ["한도와 금리, 두가지가", "필요한 직장인을 위해"],
  },
  {
    id: 16,
    title: "DH저축은행",
    site: "https://www.dhsavingsbank.co.kr/LonInfoInfo0100.act",
    img: "/savingbank/DH저축은행.png",
    subTitle: ["e-예적금담보대출", "(비대면)"],
  },
  {
    id: 17,
    title: "HB저축은행",
    site: "https://www.hbsb.co.kr/loan/secured-loan",
    img: "/savingbank/HB저축은행.png",
    subTitle: ["부동산과 유가증권을", "담보로 필요자금을 지원"],
  },
  {
    id: 18,
    title: "KB저축은행",
    site: "https://www.kbsavings.com/",
    img: "/savingbank/KB저축은행.png",
    subTitle: ["다양한 온라인", "대출 상품을 편리하게"],
  },
  {
    id: 19,
    title: "NH저축은행",
    site: "https://www.nhsavingsbank.co.kr/lnPrd/list.do",
    img: "/savingbank/NH저축은행.png",
    subTitle: ["직장인전용", "중금리 신용대출"],
  },
  {
    id: 20,
    title: "OK저축은행",
    site: "https://www.oksavingsbank.com/#/gdsLonHsList?menuCd=00174",
    img: "/savingbank/OK저축은행.png",
    subTitle: ["가계대출,사업자금,", "후순위대출까지"],
  },
  {
    id: 21,
    title: "OSB저축은행",
    site: "https://www.osb.co.kr/ib20/mnu/HOM00020",
    img: "/savingbank/OSB저축은행.png",
    subTitle: ["OSB에서 준비한", "다양한 담보대출상품"],
  },
  {
    id: 22,
    title: "SBI저축은행",
    site: "https://www.sbiapt.com/bridge/oml/bridge_w01.jsp?src=naver_ccs&kw=0124B7&ch=h00151111111&n_media=335738&n_query=SBI%EC%A0%80%EC%B6%95%EC%9D%80%ED%96%89&n_rank=4&n_ad_group=grp-a001-01-000000013624605&n_ad=nad-a001-01-000000251470216&n_keyword_id=nkw-a001-01-",
    img: "/savingbank/SBI저축은행.png",
    subTitle: ["사업자에게 딱 맞는", "아파트담보대출"],
  },
  {
    id: 23,
    title: "SKY저축은행",
    site: "https://www.skysb.co.kr/LonInfoInfo0100.act",
    img: "/savingbank/SKY저축은행.png",
    subTitle: ["예적금담보대출", "(인터넷뱅킹)"],
  },
  {
    id: 24,
    title: "THE K 저축은행",
    site: "https://www.thekbank.co.kr/loan/realestate.do",
    img: "/savingbank/THEK저축은행.png",
    subTitle: ["부동산을 담보로", "부동산담보대출"],
  },
];

export const P2P대출_TYPE = [
  {
    id: 1,
    title: "피플펀드",
    site: "https://www.peoplefund.co.kr/loans/mortgage-loans",
    img: "/p2p/피플펀드.png",
    subTitle: ["기존 대출이 있어도 아파트가", "있으면 최대 15억까지"],
  },
  {
    id: 2,
    title: "8퍼센트",
    site: "https://8percent.kr/loan/index/mortgage/",
    img: "/p2p/8퍼센트.png",
    subTitle: ["전문 심사역이 케어해주는", "부동산 담보 대출"],
  },
  {
    id: 3,
    title: "투게더앱스",
    site: "https://loan.together.co.kr/",
    img: "/p2p/투게더앱스.png",
    subTitle: ["주택담보대출 1위", "투게더펀딩"],
  },
  {
    id: 4,
    title: "프로핏",
    site: "https://www.pro-fit.co.kr/loan/main",
    img: "/p2p/프로핏.png",
    subTitle: ["대출 프로세스로", "정확하고 확실한 대출"],
  },
  {
    id: 5,
    title: "오션펀딩",
    site: "https://www.oceanfunding.co.kr/loan/mortgage",
    img: "/p2p/오션펀딩.png",
    subTitle: ["오션펀딩에서 비대면으로", "간편하게 신청하세요"],
  },
  {
    id: 6,
    title: "어니스트펀드",
    site: "https://www.honestfund.kr/v2/loan/mortgage/guide",
    img: "/p2p/어니스트펀드.png",
    subTitle: ["꽉 막혔던 대출 한도", "시원하게 뚫어 드릴게요!"],
  },
  {
    id: 7,
    title: "헬로펀딩",
    site: "https://www.hellofunding.co.kr/sp/loan",
    img: "/p2p/헬로펀딩.png",
    subTitle: ["아파트 담보대출은", "헬로펀딩"],
  },
  {
    id: 8,
    title: "하이펀딩",
    site: "https://hifunding.co.kr/loan",
    img: "/p2p/하이펀딩.png",
    subTitle: ["간단한 상담을 통해", "손쉽게 대출신청"],
  },
  {
    id: 9,
    title: "리딩플러스",
    site: "https://leadingplusfunding.com/loan/request/apt",
    img: "/p2p/리딩플러스.png",
    subTitle: ["리딩플러스로 한도를", "플러스하세요"],
  },
  {
    id: 10,
    title: "윙크스톤",
    site: "https://loan.winkstone.com/#",
    img: "/p2p/윙크스톤.png",
    subTitle: ["쉽고 빠른 비대면", "대출 서비스"],
  },
];

export const 서민금융대부_TYPE = [
  {
    id: 1,
    title: "리드코프",
    site: "https://www.leadcorp.co.kr/",
    img: "/daebu/리드코프.png",
    subTitle: ["신용대출, 담보대출,", "생활금융 필요할 땐"],
  },
  {
    id: 2,
    title: "태강대부",
    site: "https://www.cashbucks.co.kr/",
    img: "/daebu/태강대부.png",
    subTitle: ["바른 소비자", "금융기업의 기준"],
  },
  {
    id: 3,
    title: "바로크레디트대부",
    site: "https://m.barocredit.net/",
    img: "/daebu/바로크레디트대부.png",
    subTitle: ["믿을 수 있는", "대부업체"],
  },
  {
    id: 4,
    title: "미래크레디트대부",
    site: "https://www.futurecredit.co.kr/",
    img: "/daebu/미래크레디트대부.png",
    subTitle: ["저신용자도 대출 가능한", "정직한 대출"],
  },
  {
    id: 5,
    title: "밀리언캐쉬대부",
    site: "https://www.millioncash.co.kr/",
    img: "/daebu/밀리언캐쉬대부.png",
    subTitle: ["여름의 시원한 그늘처럼", "당신을 기다립니다"],
  },
  {
    id: 6,
    title: "골든캐피탈대부",
    site: "http://www.goldencapital.co.kr/",
    img: "/daebu/골든캐피탈대부.png",
    subTitle: ["대출에도", "골드타임이 있습니다"],
  },
  {
    id: 7,
    title: "넥스젠파이낸스대부",
    site: "https://www.nexgenfinance.co.kr:1443/",
    img: "/daebu/넥스젠파이낸스대부.png",
    subTitle: ["그대에게 꼭 맞는", "맞춤대출"],
  },
  {
    id: 8,
    title: "에이원대부캐피탈",
    site: "https://www.a1capital.co.kr/sub02_01.html",
    img: "/daebu/에이원대부캐피탈.png",
    subTitle: ["신용만으로", "자금용도에 관계없이"],
  },
  {
    id: 9,
    title: "스타크레디트대부",
    site: "https://www.happycappy.co.kr/",
    img: "/daebu/스타크레디트대부.png",
    subTitle: ["담보대출 가능여부", "가능한도 확인해보세요"],
  },
  {
    id: 10,
    title: "엘하비스트대부",
    site: "https://www.lharvest.co.kr:1443/",
    img: "/daebu/엘하비스트대부.png",
    subTitle: ["새로운 시작을 꿈꾸는", "청춘 드림론"],
  },
  {
    id: 11,
    title: "엠에스아이대부",
    site: "https://money-store.co.kr/",
    img: "/daebu/엠에스아이대부.png",
    subTitle: ["쉽게, 빠르게,", "안전한 대출 시스템"],
  },
  {
    id: 12,
    title: "오케이파이낸셜대부",
    site: "http://www.okfinancial.co.kr/",
    img: "/daebu/오케이파이낸셜대부.png",
    subTitle: ["건강한 금융라이프를", "지향하는"],
  },
  {
    id: 13,
    title: "앤알캐피탈대부",
    site: "https://www.nrcapital.co.kr/",
    img: "/daebu/앤알캐피탈대부.png",
    subTitle: ["당신의 꿈, 더 높이", "날 수 있습니다"],
  },
  {
    id: 14,
    title: "유아이크레디트대부",
    site: "https://youicredit.co.kr/",
    img: "/daebu/유아이크레디트대부.png",
    subTitle: ["여성에게 희망을", "드리는 대출!"],
  },
  {
    id: 15,
    title: "옐로우캐피탈대부",
    site: "https://www.yellowcapital.co.kr/",
    img: "/daebu/옐로우캐피탈대부.png",
    subTitle: ["힘이 되는 금융", "희망을 드리는 서비스"],
  },
  {
    id: 16,
    title: "유미캐피탈대부",
    site: "https://www.youmecapital.com/",
    img: "/daebu/유미캐피탈대부.png",
    subTitle: ["언제 어디서나", "쉽고 빠르게"],
  },
  {
    id: 17,
    title: "테크메이트코리아대부",
    site: "https://www.techmatekorea.co.kr/",
    img: "/daebu/테크메이트코리아대부.png",
    subTitle: ["지속적으로", "성장하는 기업"],
  },
];

export const 상담사_TYPE = [
  {
    id: 1,
    title: "대*은행",
    // site: "https://www.leadcorp.co.kr/",
    img: "/people/대*은행.png",
    subTitle: ["무방문 무서류 즉시송금", "전화한통 당일송금 OK"],
  },
  {
    id: 2,
    title: "부*은행",
    // site: "https://www.cashbucks.co.kr/",
    img: "/people/부*은행.png",
    subTitle: ["무방문 무서류 즉시송금", "전화한통 당일송금 OK"],
  },
  {
    id: 3,
    title: "기*은행",
    // site: "https://m.barocredit.net/",
    img: "/people/기*은행.png",
    subTitle: ["무방문 무서류 즉시송금", "전화한통 당일송금 OK"],
  },
  {
    id: 4,
    title: "한*생명",
    // site: "https://www.futurecredit.co.kr/",
    img: "/people/한*생명.png",
    subTitle: ["무방문 무서류 즉시송금", "전화한통 당일송금 OK"],
  },
  {
    id: 5,
    title: "삼*화재",
    // site: "https://www.millioncash.co.kr/",
    img: "/people/삼*화재.png",
    subTitle: ["무방문 무서류 즉시송금", "전화한통 당일송금 OK"],
  },
  {
    id: 6,
    title: "삼*생명",
    // site: "http://www.goldencapital.co.kr/",
    img: "/people/삼*생명.png",
    subTitle: ["무방문 무서류 즉시송금", "전화한통 당일송금 OK"],
  },
  {
    id: 7,
    title: "현*해상",
    // site: "https://www.nexgenfinance.co.kr:1443/",
    img: "/people/현*해상.png",
    subTitle: ["무방문 무서류 즉시송금", "전화한통 당일송금 OK"],
  },
  {
    id: 8,
    title: "하*생명",
    // site: "https://www.a1capital.co.kr/sub02_01.html",
    img: "/people/하*생명.png",
    subTitle: ["무방문 무서류 즉시송금", "전화한통 당일송금 OK"],
  },
  {
    id: 9,
    title: "K*손해보험",
    // site: "https://www.happycappy.co.kr/",
    img: "/people/K*손해보험.png",
    subTitle: ["무방문 무서류 즉시송금", "전화한통 당일송금 OK"],
  },
  {
    id: 10,
    title: "흥*생명",
    // site: "https://www.lharvest.co.kr:1443/",
    img: "/people/흥*생명.png",
    subTitle: ["무방문 무서류 즉시송금", "전화한통 당일송금 OK"],
  },
  {
    id: 11,
    title: "신*생명",
    // site: "https://money-store.co.kr/",
    img: "/people/신*생명.png",
    subTitle: ["무방문 무서류 즉시송금", "전화한통 당일송금 OK"],
  },
  {
    id: 12,
    title: "푸*생명",
    // site: "http://www.okfinancial.co.kr/",
    img: "/people/푸*생명.png",
    subTitle: ["무방문 무서류 즉시송금", "전화한통 당일송금 OK"],
  },
  {
    id: 13,
    title: "롯*손해보험",
    // site: "https://www.nrcapital.co.kr/",
    img: "/people/롯*손해보험.png",
    subTitle: ["무방문 무서류 즉시송금", "전화한통 당일송금 OK"],
  },
  {
    id: 14,
    title: "농*손해보험",
    // site: "https://youicredit.co.kr/",
    img: "/people/농*손해보험.png",
    subTitle: ["무방문 무서류 즉시송금", "전화한통 당일송금 OK"],
  },
  {
    id: 15,
    title: "수*",
    // site: "https://www.yellowcapital.co.kr/",
    img: "/people/수*.png",
    subTitle: ["무방문 무서류 즉시송금", "전화한통 당일송금 OK"],
  },
  {
    id: 16,
    title: "신*",
    // site: "https://www.youmecapital.com/",
    img: "/people/신*.png",
    subTitle: ["무방문 무서류 즉시송금", "전화한통 당일송금 OK"],
  },
  {
    id: 17,
    title: "현*캐피탈",
    // site: "https://www.techmatekorea.co.kr/",
    img: "/people/현*캐피탈.png",
    subTitle: ["무방문 무서류 즉시송금", "전화한통 당일송금 OK"],
  },
  {
    id: 18,
    title: "SB*저축은행",
    // site: "https://www.techmatekorea.co.kr/",
    img: "/people/SB*저축은행.png",
    subTitle: ["무방문 무서류 즉시송금", "전화한통 당일송금 OK"],
  },
  {
    id: 19,
    title: "O*저축은행",
    // site: "https://www.techmatekorea.co.kr/",
    img: "/people/O*저축은행.png",
    subTitle: ["무방문 무서류 즉시송금", "전화한통 당일송금 OK"],
  },
  {
    id: 20,
    title: "애*온저축은행",
    // site: "https://www.techmatekorea.co.kr/",
    img: "/people/애*온저축은행.png",
    subTitle: ["무방문 무서류 즉시송금", "전화한통 당일송금 OK"],
  },
  {
    id: 21,
    title: "하*캐피탈",
    // site: "https://www.techmatekorea.co.kr/",
    img: "/people/하*캐피탈.png",
    subTitle: ["무방문 무서류 즉시송금", "전화한통 당일송금 OK"],
  },
  {
    id: 22,
    title: "D*B캐피탈",
    // site: "https://www.techmatekorea.co.kr/",
    img: "/people/D*B캐피탈.png",
    subTitle: ["무방문 무서류 즉시송금", "전화한통 당일송금 OK"],
  },
  {
    id: 23,
    title: "농*캐피탈",
    // site: "https://www.techmatekorea.co.kr/",
    img: "/people/농*캐피탈.png",
    subTitle: ["무방문 무서류 즉시송금", "전화한통 당일송금 OK"],
  },
];
