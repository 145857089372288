import styled from "styled-components";
export const MoLinkContainer = styled.div`
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;

  width: 100%;
  display: flex;
`;

export const Button = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
  flex: 1;
  height: 72px;
  border: none;
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  color: ${({ $color }) => $color};
`;

export const Wrapper = styled.div`
  display: flex;

  gap: 8px;

  align-items: center;
  justify-content: center;

  @media (max-width: 360px) {
    padding: 0 10px;
  }
  padding: 0 20px;
`;

export const Icon = styled.img`
  width: 24px;
  height: 24px;
`;

export const ButtonTitle = styled.span`
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
`;

export const ButtonDesc = styled.span`
  font-size: 14px;
  line-height: 18px;
`;
