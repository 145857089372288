import React from "react";

import {
  탭_메뉴_TYPE,
  이사_비교_TYPE,
  인터넷_비교_TYPE,
  인테리어_비교_TYPE,
  렌트리스_비교_TYPE,
  생활가전렌탈_비교_TYPE,
  대출비교_탭_메뉴_TYPE,
  플랫폼_TYPE,
  서민금융대부_TYPE,
  P2P대출_TYPE,
  저축은행_TYPE,
  캐피탈_TYPE,
  보험사_TYPE,
  은행권_TYPE,
  상담사_TYPE,
} from "../../../constants/defines";
import Bottom from "../../Drawer/Bottom";
import DrawerItem from "../../DrawerItem";
import IntroMo from "../../../components/Intro/Mobile/index";
import * as S from "./styles";

const TabMenuMo = ({ handleTabClick, activeTab, setActiveTab }) => {
  const [loansActiveTab, setLoansActiveTab] = React.useState(1);
  const [visible_금융권선택, setVisible_금융권선택] = React.useState(false);
  const [isFixed, setIsFixed] = React.useState(false);

  const handleItemClick = (site) => {
    window.open(`${site}`, "_blank", "noopener, noreferrer");
  };

  const handleLoansTabClick = (id) => {
    setLoansActiveTab(id);
  };
  const 금융권선택_모달 = () => {
    setVisible_금융권선택((visible_금융권선택) => !visible_금융권선택);
  };

  React.useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 300) {
        setIsFixed(true);
      } else {
        setIsFixed(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <S.Container>
      <Bottom
        visible={visible_금융권선택}
        title="금융권 선택"
        closeModal={금융권선택_모달}
      >
        {대출비교_탭_메뉴_TYPE?.map(({ title, id }, index) => {
          const active = loansActiveTab === id;
          return (
            <DrawerItem
              onClick={() => {
                handleLoansTabClick(id);
                금융권선택_모달();
              }}
              active={active}
            >
              {title}
            </DrawerItem>
          );
        })}
      </Bottom>
      <S.Items isFixed={isFixed}>
        {탭_메뉴_TYPE.map(({ title, id }, index) => {
          return (
            <S.Item
              onClick={() => handleTabClick(id)}
              active={activeTab === id}
            >
              {title}
            </S.Item>
          );
        })}
      </S.Items>
      <S.Wrapper>
        {activeTab === 1 && (
          <S.Lists>
            {이사_비교_TYPE.map(({ title, id, site, img, subTitle }, index) => {
              return (
                <S.List onClick={() => handleItemClick(site)}>
                  <img src={img} alt="회사별_이미지" />
                  <S.Title>{title}</S.Title>
                  <S.Detail>{subTitle?.[0]}</S.Detail>
                  <S.Detail>{subTitle?.[1]}</S.Detail>
                </S.List>
              );
            })}
          </S.Lists>
        )}
        {activeTab === 2 && (
          <S.Lists>
            {인터넷_비교_TYPE.map(
              ({ title, id, site, img, subTitle }, index) => {
                return (
                  <S.List onClick={() => handleItemClick(site)}>
                    <img src={img} alt="회사별_이미지" />
                    <S.Title>{title}</S.Title>
                    <S.Detail>{subTitle?.[0]}</S.Detail>
                    <S.Detail>{subTitle?.[1]}</S.Detail>
                  </S.List>
                );
              }
            )}
          </S.Lists>
        )}
        {activeTab === 3 && (
          <S.Lists>
            {인테리어_비교_TYPE.map(
              ({ title, id, site, img, subTitle }, index) => {
                return (
                  <S.List onClick={() => handleItemClick(site)}>
                    <img src={img} alt="회사별_이미지" />
                    <S.Title>{title}</S.Title>
                    <S.Detail>{subTitle?.[0]}</S.Detail>
                    <S.Detail>{subTitle?.[1]}</S.Detail>
                  </S.List>
                );
              }
            )}
          </S.Lists>
        )}
        {activeTab === 4 && (
          <>
            <S.LoanTitle>금융권 선택</S.LoanTitle>
            {대출비교_탭_메뉴_TYPE.map(({ title, id }, index) => {
              const select = loansActiveTab === id;
              return (
                select && (
                  <S.SelectButton
                    onClick={() => 금융권선택_모달()}
                    active={loansActiveTab === id}
                  >
                    <p>{title}</p>
                    <img src="/arrow_down.png" alt="이미지"></img>
                  </S.SelectButton>
                )
              );
            })}
            {loansActiveTab === 1 && (
              <S.Lists>
                {플랫폼_TYPE.map(
                  ({ title, id, site, img, subTitle }, index) => {
                    return (
                      <S.List onClick={() => handleItemClick(site)}>
                        <img src={img} alt="회사별_이미지" />
                        <S.Title>{title}</S.Title>
                        <S.Detail>{subTitle?.[0]}</S.Detail>
                        <S.Detail>{subTitle?.[1]}</S.Detail>
                      </S.List>
                    );
                  }
                )}
              </S.Lists>
            )}
            {loansActiveTab === 2 && (
              <S.Lists>
                {은행권_TYPE.map(
                  ({ title, id, site, img, subTitle }, index) => {
                    return (
                      <S.List onClick={() => handleItemClick(site)}>
                        <img src={img} alt="회사별_이미지" />
                        <S.Title>{title}</S.Title>
                        <S.Detail>{subTitle?.[0]}</S.Detail>
                        <S.Detail>{subTitle?.[1]}</S.Detail>
                      </S.List>
                    );
                  }
                )}
              </S.Lists>
            )}
            {loansActiveTab === 3 && (
              <S.Lists>
                {보험사_TYPE.map(
                  ({ title, id, site, img, subTitle }, index) => {
                    return (
                      <S.List onClick={() => handleItemClick(site)}>
                        <img src={img} alt="회사별_이미지" />
                        <S.Title>{title}</S.Title>
                        <S.Detail>{subTitle?.[0]}</S.Detail>
                        <S.Detail>{subTitle?.[1]}</S.Detail>
                      </S.List>
                    );
                  }
                )}
              </S.Lists>
            )}
            {loansActiveTab === 4 && (
              <S.Lists>
                {캐피탈_TYPE.map(
                  ({ title, id, site, img, subTitle }, index) => {
                    return (
                      <S.List onClick={() => handleItemClick(site)}>
                        <img src={img} alt="회사별_이미지" />
                        <S.Title>{title}</S.Title>
                        <S.Detail>{subTitle?.[0]}</S.Detail>
                        <S.Detail>{subTitle?.[1]}</S.Detail>
                      </S.List>
                    );
                  }
                )}
              </S.Lists>
            )}
            {loansActiveTab === 5 && (
              <S.Lists>
                {저축은행_TYPE.map(
                  ({ title, id, site, img, subTitle }, index) => {
                    return (
                      <S.List onClick={() => handleItemClick(site)}>
                        <img src={img} alt="회사별_이미지" />
                        <S.Title>{title}</S.Title>
                        <S.Detail>{subTitle?.[0]}</S.Detail>
                        <S.Detail>{subTitle?.[1]}</S.Detail>
                      </S.List>
                    );
                  }
                )}
              </S.Lists>
            )}
            {loansActiveTab === 6 && (
              <S.Lists>
                {P2P대출_TYPE.map(
                  ({ title, id, site, img, subTitle }, index) => {
                    return (
                      <S.List onClick={() => handleItemClick(site)}>
                        <img src={img} alt="회사별_이미지" />
                        <S.Title>{title}</S.Title>
                        <S.Detail>{subTitle?.[0]}</S.Detail>
                        <S.Detail>{subTitle?.[1]}</S.Detail>
                      </S.List>
                    );
                  }
                )}
              </S.Lists>
            )}
            {loansActiveTab === 7 && (
              <S.Lists>
                {서민금융대부_TYPE.map(
                  ({ title, id, site, img, subTitle }, index) => {
                    return (
                      <S.List onClick={() => handleItemClick(site)}>
                        <img src={img} alt="회사별_이미지" />
                        <S.Title>{title}</S.Title>
                        <S.Detail>{subTitle?.[0]}</S.Detail>
                        <S.Detail>{subTitle?.[1]}</S.Detail>
                      </S.List>
                    );
                  }
                )}
              </S.Lists>
            )}
            {loansActiveTab === 8 && (
              <S.Lists>
                {상담사_TYPE.map(
                  ({ title, id, site, img, subTitle }, index) => {
                    return (
                      <S.ListNotClick
                      // onClick={() => handleItemClick(site)}
                      >
                        <img src={img} alt="회사별_이미지" />
                        <S.Title>{title}</S.Title>
                        <S.Detail>{subTitle?.[0]}</S.Detail>
                        <S.Detail>{subTitle?.[1]}</S.Detail>
                      </S.ListNotClick>
                    );
                  }
                )}
              </S.Lists>
            )}
          </>
        )}
        {activeTab === 5 && (
          <S.Lists>
            {렌트리스_비교_TYPE.map(
              ({ title, id, site, img, subTitle }, index) => {
                return (
                  <S.List onClick={() => handleItemClick(site)}>
                    <img src={img} alt="회사별_이미지" />
                    <S.Title>{title}</S.Title>
                    <div>{subTitle?.[0]}</div>
                    <div>{subTitle?.[1]}</div>
                  </S.List>
                );
              }
            )}
          </S.Lists>
        )}
        {activeTab === 6 && (
          <S.Lists>
            {생활가전렌탈_비교_TYPE.map(
              ({ title, id, site, img, subTitle }, index) => {
                return (
                  <S.List onClick={() => handleItemClick(site)}>
                    <img src={img} alt="회사별_이미지" />
                    <S.Title>{title}</S.Title>
                    <div>{subTitle?.[0]}</div>
                    <div>{subTitle?.[1]}</div>
                  </S.List>
                );
              }
            )}
          </S.Lists>
        )}
        {activeTab === 7 && (
          <>
            <IntroMo></IntroMo>
          </>
        )}
      </S.Wrapper>
    </S.Container>
  );
};

export default TabMenuMo;
