import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
`;

export const Items = styled.div`
  margin: 0 auto;
  width: 1120px;
  height: 60px;
  padding: 40px 0 40px;
`;

export const ItemWrapper = styled.div`
  position: absolute;
  width: 1120px;
  height: 20px;
  background-color: #fff;
  margin: 0 auto;
  display: ${({ isFixed }) => (isFixed ? "block" : "none")};
  z-index: 9999;
  top: -20px;
`;

export const Item = styled.div`
  width: 1120px;
  margin: ${({ isFixed }) => (isFixed ? "20px 0 " : "0 auto")};
  margin: 0 auto;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  border-radius: 8px;

  background-color: #e1f9fa;
  margin-bottom: 40px;

  position: ${({ isFixed }) => (isFixed ? "fixed" : "relative")};
  top: ${({ isFixed }) => (isFixed ? "20px " : "0")};

  button:first-of-type {
    border-radius: 8px 0 0 8px;
  }
  button:last-of-type {
    border-radius: 0 8px 8px 0;
  }
`;

export const ButtonItem = styled.button`
  height: 60px;
  cursor: pointer;
  width: calc(1120px / 6);
  color: ${(props) => (props.active ? "#fff" : "#04b4bb")};
  font-size: 18px;
  font-weight: 700;
  border: ${(props) => (props.active ? "1px solid #fff" : "none")};
  border-radius: ${(props) => (props.active ? "8px !important" : "none")};
  background-color: ${(props) => (props.active ? "#04b4bb" : "#e1f9fa")};
  font-family: Pretendard;
`;

export const Lists = styled.div`
  img {
    width: 264px;
    height: 136px;
    border: 1px solid #d3d7de;
  }
  display: grid;

  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
`;

export const Title = styled.div`
  padding: 14px 0 4px 0;
  color: #04b4bb;
  font-size: 20px;
  font-weight: 700;
`;

export const Wrapper = styled.div`
  margin: 0 auto;
  width: 1120px;
  height: auto;
  padding-bottom: 160px;
`;

export const List = styled.div`
  padding-bottom: 20px;
  text-align: center;
  cursor: pointer;
`;

export const ListNotClick = styled.div`
  padding-bottom: 20px;
  text-align: center;
`;

export const LoanTitle = styled.div`
  font-size: 20px;
  font-weight: 700;
  width: 1120px;
  margin: 0 auto;
  padding-bottom: 20px;
`;

export const LoansTabmenu = styled.div`
  width: 1120px;
  margin: 0 auto;
  height: 40px;
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: space-evenly;

  background-color: #fff;
  margin-bottom: 40px;
  border-bottom: 1px solid #bec3cc;
`;

export const LoansButton = styled.button`
  cursor: pointer;
  height: 40px;
  width: calc(1120px / 6);
  color: ${(props) => (props.active ? "#04b4bb" : "#6d7582")};
  font-size: 18px;
  font-weight: 700;
  border: none;
  border-bottom: ${(props) => (props.active ? "3px solid #04b4bb" : "none")};
  background-color: transparent;
`;

export const MenuWrapper = styled.div`
  position: ${({ isFixed }) => (isFixed ? "fixed" : "absolute")};
  z-index: 1;
  overflow: hidden;

  display: flex;

  flex-direction: column;
  border: 2px solid #ddd;
  border-radius: 10px;
  margin-left: 1140px;
  top: ${({ isFixed }) => (isFixed ? "20px" : "280px")};
`;

export const Account = styled.div`
  position: ${({ isFixed }) => (isFixed ? "fixed" : "absolute")};
  z-index: 1;
  overflow: hidden;

  display: flex;
  top: ${({ isFixed }) => (isFixed ? "20px" : "280px")};
  flex-direction: column;
  border: none;
  border-radius: 12px;
  margin-left: 1140px;
  margin-top: 200px;
  width: 160px;
  height: 114px;
  background-color: #f3f4f6;
`;

export const AccountTitle = styled.div`
  text-align: center;
  color: #04b4bb;
  font-size: 16px;
  font-weight: 700;
  padding: 10px 0 12px;
`;

export const AccountDetail = styled.div`
  margin-left: 12px;
  font-size: 14px;
  font-weight: 500;
  padding-bottom: 3px;
`;

export const Menu = styled.button`
  display: flex;
  font-family: Pretendard;
  gap: 8px;
  border-bottom: 1px solid #ddd !important;
  align-items: center;
  justify-content: left;

  width: 160px;
  height: 60px;

  font-size: 16px;
  font-weight: 500;
  color: #333;

  cursor: pointer;

  background-color: #fff;
  border: none;

  img {
    width: 24px;
    height: 24px;
    margin-left: 20px;
  }
`;
export const Kakao = styled.button`
  display: flex;
  font-family: Pretendard;
  gap: 8px;

  align-items: center;
  justify-content: left;

  width: 160px;
  height: 60px;

  font-size: 16px;
  font-weight: 500;
  color: #333;

  cursor: pointer;

  background-color: #fff;
  border: none;

  img {
    margin-left: 20px;
    width: 24px;
    height: 24px;
  }
`;

export const AdWrapper = styled.div`
  position: ${({ isFixed }) => (isFixed ? "fixed" : "absolute")};

  z-index: -1;
  overflow: hidden;
  top: ${({ isFixed }) => (isFixed ? "20px" : "280px")};
  display: flex;

  img {
    width: 160px;
    height: 600px;
  }
  /* margin-left: ${({ isFixed }) => (isFixed ? "-180px" : "-180px")}; */
  margin-left: -180px;
  flex-direction: column;
`;
