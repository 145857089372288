import React from "react";
import * as S from "./styles";

const index = ({ 회사소개_클릭, 입점안내_신청 }) => {
  return (
    <S.Container>
      <S.Wrapper>
        <S.Logo>
          <img src="/footer_logo.png" alt="하단_로고" />
        </S.Logo>
        <S.FooterButton>
          <div onClick={회사소개_클릭}>회사소개</div>
          <div onClick={입점안내_신청}>입점안내</div>
        </S.FooterButton>
        <S.Comment>
          <div>상호 : 다비교</div>
          <div>대표번호 : 1644-7407</div>
          <div>대표 : 박병주</div>
          <div>사업자등록번호 : 153-01-03142</div>
        </S.Comment>
        <S.CommentDetail>
          <div>주소 : 서울특별시 영등포구 문래북로 116, 807</div>
          <div>개인정보책임자 : 박병주</div>
        </S.CommentDetail>
        <S.CommentWarn>
          다비교는 데이터베이스 및 온라인 정보 제공업자로서 광고 플랫폼만 제공할
          뿐 거래당사자가 아니며, 입점회원사가 제공하는 서비스에 대한 이행,
          계약사항 및 분쟁에 책임지지 않습니다.
        </S.CommentWarn>
        <S.CopyRight>
          Copyright (C) 다비교 Co.Ltd. All rights reserved
        </S.CopyRight>
      </S.Wrapper>
    </S.Container>
  );
};

export default index;
