import React from "react";

import * as S from "./styles";
import {
  탭_메뉴_TYPE,
  이사_비교_TYPE,
  인터넷_비교_TYPE,
  인테리어_비교_TYPE,
  렌트리스_비교_TYPE,
  생활가전렌탈_비교_TYPE,
  대출비교_탭_메뉴_TYPE,
  플랫폼_TYPE,
  은행권_TYPE,
  보험사_TYPE,
  캐피탈_TYPE,
  저축은행_TYPE,
  P2P대출_TYPE,
  서민금융대부_TYPE,
  상담사_TYPE,
} from "../../../constants/defines";
import IntroContent from "../../../components/Intro/Default";

const TabMenuPC = ({
  입점안내_신청,
  카톡문의_신청,
  handleTabClick,
  activeTab,
  setActiveTab,
  회사소개_클릭,
  빠른상담_신청,
}) => {
  const [loansActiveTab, setLoansActiveTab] = React.useState(1);
  const [isFixed, setIsFixed] = React.useState(false);
  const handleLoansTabClick = (id) => {
    setLoansActiveTab(id);
  };
  const handleItemClick = (site) => {
    window.open(`${site}`, "_blank", "noopener, noreferrer");
  };
  React.useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 240) {
        setIsFixed(true);
      } else {
        setIsFixed(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <S.Container>
        <S.Items>
          <S.Item isFixed={isFixed}>
            {탭_메뉴_TYPE.map(({ title, id }, index) => {
              return (
                <S.ButtonItem
                  onClick={() => handleTabClick(id)}
                  active={activeTab === id}
                >
                  {title}
                </S.ButtonItem>
              );
            })}
            <S.ItemWrapper isFixed={isFixed} />
          </S.Item>

          <S.AdWrapper isFixed={isFixed}>
            <img src="/ad_jordan.png" alt="조던이미지"></img>
          </S.AdWrapper>
          <S.MenuWrapper isFixed={isFixed}>
            <S.Menu onClick={회사소개_클릭}>
              <img src="/menu/menu1.png" alt="회사소개" />
              회사소개
            </S.Menu>
            <S.Menu onClick={입점안내_신청}>
              <img src="/menu/menu2.png" alt="입점안내" />
              입점안내
            </S.Menu>
            <S.Kakao onClick={카톡문의_신청}>
              <img src="/menu/menu4.png" alt="카카오톡 문의" />
              카카오톡 문의
            </S.Kakao>
          </S.MenuWrapper>
          <S.Account isFixed={isFixed}>
            <S.AccountTitle>입금계좌</S.AccountTitle>
            <S.AccountDetail>국민은행</S.AccountDetail>
            <S.AccountDetail>491037-01-008995</S.AccountDetail>
            <S.AccountDetail>예금주 박병주</S.AccountDetail>
          </S.Account>
        </S.Items>

        <S.Wrapper>
          {activeTab === 1 && (
            <S.Lists>
              {이사_비교_TYPE.map(
                ({ title, id, site, img, subTitle }, index) => {
                  return (
                    <S.List onClick={() => handleItemClick(site)}>
                      <img src={img} alt="회사별_이미지" />
                      <S.Title>{title}</S.Title>
                      <div>{subTitle?.[0]}</div>
                      <div>{subTitle?.[1]}</div>
                    </S.List>
                  );
                }
              )}
            </S.Lists>
          )}
          {activeTab === 2 && (
            <S.Lists>
              {인터넷_비교_TYPE.map(
                ({ title, id, site, img, subTitle }, index) => {
                  return (
                    <S.List onClick={() => handleItemClick(site)}>
                      <img src={img} alt="회사별_이미지" />
                      <S.Title>{title}</S.Title>
                      <div>{subTitle?.[0]}</div>
                      <div>{subTitle?.[1]}</div>
                    </S.List>
                  );
                }
              )}
            </S.Lists>
          )}
          {activeTab === 3 && (
            <S.Lists>
              {인테리어_비교_TYPE.map(
                ({ title, id, site, img, subTitle }, index) => {
                  return (
                    <S.List onClick={() => handleItemClick(site)}>
                      <img src={img} alt="회사별_이미지" />
                      <S.Title>{title}</S.Title>
                      <div>{subTitle?.[0]}</div>
                      <div>{subTitle?.[1]}</div>
                    </S.List>
                  );
                }
              )}
            </S.Lists>
          )}
          {activeTab === 4 && (
            <>
              <S.LoanTitle>금융권 선택</S.LoanTitle>
              <S.LoansTabmenu>
                {대출비교_탭_메뉴_TYPE.map(({ title, id }, index) => {
                  return (
                    <S.LoansButton
                      onClick={() => handleLoansTabClick(id)}
                      active={loansActiveTab === id}
                    >
                      {title}
                    </S.LoansButton>
                  );
                })}
              </S.LoansTabmenu>
              {loansActiveTab === 1 && (
                <S.Lists>
                  {플랫폼_TYPE.map(
                    ({ title, id, site, img, subTitle }, index) => {
                      return (
                        <S.List onClick={() => handleItemClick(site)}>
                          <img src={img} alt="회사별_이미지" />
                          <S.Title>{title}</S.Title>
                          <div>{subTitle?.[0]}</div>
                          <div>{subTitle?.[1]}</div>
                        </S.List>
                      );
                    }
                  )}
                </S.Lists>
              )}
              {loansActiveTab === 2 && (
                <S.Lists>
                  {은행권_TYPE.map(
                    ({ title, id, site, img, subTitle }, index) => {
                      return (
                        <S.List onClick={() => handleItemClick(site)}>
                          <img src={img} alt="회사별_이미지" />
                          <S.Title>{title}</S.Title>
                          <div>{subTitle?.[0]}</div>
                          <div>{subTitle?.[1]}</div>
                        </S.List>
                      );
                    }
                  )}
                </S.Lists>
              )}
              {loansActiveTab === 3 && (
                <S.Lists>
                  {보험사_TYPE.map(
                    ({ title, id, site, img, subTitle }, index) => {
                      return (
                        <S.List onClick={() => handleItemClick(site)}>
                          <img src={img} alt="회사별_이미지" />
                          <S.Title>{title}</S.Title>
                          <div>{subTitle?.[0]}</div>
                          <div>{subTitle?.[1]}</div>
                        </S.List>
                      );
                    }
                  )}
                </S.Lists>
              )}
              {loansActiveTab === 4 && (
                <S.Lists>
                  {캐피탈_TYPE.map(
                    ({ title, id, site, img, subTitle }, index) => {
                      return (
                        <S.List onClick={() => handleItemClick(site)}>
                          <img src={img} alt="회사별_이미지" />
                          <S.Title>{title}</S.Title>
                          <div>{subTitle?.[0]}</div>
                          <div>{subTitle?.[1]}</div>
                        </S.List>
                      );
                    }
                  )}
                </S.Lists>
              )}
              {loansActiveTab === 5 && (
                <S.Lists>
                  {저축은행_TYPE.map(
                    ({ title, id, site, img, subTitle }, index) => {
                      return (
                        <S.List onClick={() => handleItemClick(site)}>
                          <img src={img} alt="회사별_이미지" />
                          <S.Title>{title}</S.Title>
                          <div>{subTitle?.[0]}</div>
                          <div>{subTitle?.[1]}</div>
                        </S.List>
                      );
                    }
                  )}
                </S.Lists>
              )}
              {loansActiveTab === 6 && (
                <S.Lists>
                  {P2P대출_TYPE.map(
                    ({ title, id, site, img, subTitle }, index) => {
                      return (
                        <S.List onClick={() => handleItemClick(site)}>
                          <img src={img} alt="회사별_이미지" />
                          <S.Title>{title}</S.Title>
                          <div>{subTitle?.[0]}</div>
                          <div>{subTitle?.[1]}</div>
                        </S.List>
                      );
                    }
                  )}
                </S.Lists>
              )}
              {loansActiveTab === 7 && (
                <S.Lists>
                  {서민금융대부_TYPE.map(
                    ({ title, id, site, img, subTitle }, index) => {
                      return (
                        <S.List onClick={() => handleItemClick(site)}>
                          <img src={img} alt="회사별_이미지" />
                          <S.Title>{title}</S.Title>
                          <div>{subTitle?.[0]}</div>
                          <div>{subTitle?.[1]}</div>
                        </S.List>
                      );
                    }
                  )}
                </S.Lists>
              )}
              {loansActiveTab === 8 && (
                <S.Lists>
                  {상담사_TYPE.map(
                    ({ title, id, site, img, subTitle }, index) => {
                      return (
                        <S.ListNotClick
                        // onClick={() => handleItemClick(site)}
                        >
                          <img src={img} alt="회사별_이미지" />
                          <S.Title>{title}</S.Title>
                          <div>{subTitle?.[0]}</div>
                          <div>{subTitle?.[1]}</div>
                        </S.ListNotClick>
                      );
                    }
                  )}
                </S.Lists>
              )}
            </>
          )}
          {activeTab === 5 && (
            <S.Lists>
              {렌트리스_비교_TYPE.map(
                ({ title, id, site, img, subTitle }, index) => {
                  return (
                    <S.List onClick={() => handleItemClick(site)}>
                      <img src={img} alt="회사별_이미지" />
                      <S.Title>{title}</S.Title>
                      <div>{subTitle?.[0]}</div>
                      <div>{subTitle?.[1]}</div>
                    </S.List>
                  );
                }
              )}
            </S.Lists>
          )}
          {activeTab === 6 && (
            <S.Lists>
              {생활가전렌탈_비교_TYPE.map(
                ({ title, id, site, img, subTitle }, index) => {
                  return (
                    <S.List onClick={() => handleItemClick(site)}>
                      <img src={img} alt="회사별_이미지" />
                      <S.Title>{title}</S.Title>
                      <div>{subTitle?.[0]}</div>
                      <div>{subTitle?.[1]}</div>
                    </S.List>
                  );
                }
              )}
            </S.Lists>
          )}
          {activeTab === 7 && (
            <>
              <IntroContent
                빠른상담_신청={빠른상담_신청}
                카톡문의_신청={카톡문의_신청}
              ></IntroContent>
            </>
          )}
        </S.Wrapper>
      </S.Container>
    </>
  );
};

export default TabMenuPC;
