import React from "react";

import { motion, AnimatePresence } from "framer-motion";
// import { AnimatePresence, motion } from "framer-motion/dist/framer-motion";
import styled, { css } from "styled-components";

// import { isBrowser } from "../../commons/utils/index";

import isServer from "../../utils/isServer";

import preventBackgroundScorll from "../../utils/preventBackgroundScorll";

const Container = styled(motion.div)`
  position: fixed;
  inset: 0;
  z-index: 5000;

  display: ${({ visible }) => (visible ? "block" : "none")};

  @media screen and (max-width: 768px) {
    display: ${({ visible }) => (visible ? "block" : "none")};

    background-color: rgb(0 0 0 / 40%);
  }
`;

const BackgroundButton = styled(motion.button)`
  position: fixed;
  inset: 0;
  z-index: 1;

  display: none;

  width: 100vw;
  height: 100vh;
  background: none;
  @media screen and (max-width: 768px) {
    display: block;
  }
`;

const Modal = styled(motion.div)`
  position: absolute;
  bottom: 0;
  z-index: 5001;

  width: calc(100% - 40px);
  height: ${({ height }) => (height ? `${height}px` : "60vh")};
  padding: 0 20px;

  background: #fff;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;

  ${({ test }) =>
    test &&
    css`
      height: 395px;
      padding: 0;
    `}
`;

const ButtonContainer = styled(motion.div)`
  position: absolute;
  bottom: 0;
  z-index: 5002;

  display: flex;

  width: 100%;
  height: 60px;
`;

const BottomButton = styled.button`
  flex: 1;

  height: 100%;

  font-size: 18px;
  font-weight: bold;
  color: #fff;

  background-color: ${({ color }) => color || "#ffc400"};
`;

const TitleContainer = styled(motion.div)`
  display: flex;

  align-items: center;
  justify-content: left;

  height: 40px;
  margin: 15px 0 5px;

  h4 {
    padding: 24px 0;

    font-family: ${({ theme }) => theme?.fontFamily} !important;
    font-size: 20px;
    font-weight: bold;
    text-align: left;
  }
`;

const SubTitleContainer = styled(motion.div)`
  font-size: 16px;
  line-height: 24px;
  color: #333;

  h4 {
    font-family: ${({ theme }) => theme?.fontFamily} !important;
  }
`;

const ContentContainer = styled(motion.div)`
  display: flex;

  flex-direction: column;

  gap: 10px;

  height: ${({ hasButton, isFull }) => {
    if (isFull) {
      return "60vh";
    }
    if (hasButton) {
      return "calc(60vh - 164px)";
    }

    return "calc(60vh - 100px)";
  }};
  overflow-y: scroll;

  font-size: 14px;
  color: #929292;

  ${({ test }) =>
    test &&
    css`
      height: 100%;
    `}
`;

const variants = {
  hide: { y: "70vh" },
  reveal: { y: 0 },
  close: { y: "70vh" },
};

const Bottom = ({
  visible,
  closeModal,
  title,
  onOk,
  okColor,
  okText,
  onCancel,
  cancelText,
  cancelColor,
  children,
  height,
  isFull,
  test,
  subTitle,
}) => {
  const isBrowser = !isServer();

  const body = isBrowser && document.querySelector("body");
  const scrollPosition = 0;

  React.useEffect(() => {
    if (visible) {
      preventBackgroundScorll.enable(body, scrollPosition);
      return;
    }
    preventBackgroundScorll.disable(body, scrollPosition);
    return () => {
      preventBackgroundScorll.disable(body, scrollPosition);
    };
  }, [visible]);

  return (
    <AnimatePresence>
      <Container
        key={`bottom-modal-${visible ? "GOOD" : "BAD"}`}
        visible={visible}
      >
        <BackgroundButton onClick={closeModal} />
        <Modal
          variants={variants}
          initial="hide"
          animate="reveal"
          transition={{
            duration: 0.5,
            // ease: "ease-in-out"
          }}
          exit="close"
          height={height}
          test={test}
        >
          {title && (
            <TitleContainer>
              <h4>{title}</h4>
            </TitleContainer>
          )}

          {subTitle && (
            <SubTitleContainer>
              <h4>{subTitle}</h4>
            </SubTitleContainer>
          )}

          <ContentContainer
            test={test}
            isFull={isFull}
            hasButton={onOk || onCancel}
          >
            {children}
          </ContentContainer>
        </Modal>

        {(onOk || onCancel) && (
          <ButtonContainer
            variants={variants}
            initial="hide"
            animate="reveal"
            exit="close"
            transition={{
              duration: 0.5,
              // ease: "ease-in-out"
            }}
          >
            {onCancel && (
              <BottomButton onClick={onCancel} color={cancelColor}>
                {cancelText}
              </BottomButton>
            )}
            {onOk && (
              <BottomButton onClick={onOk} color={okColor}>
                {okText}
              </BottomButton>
            )}
          </ButtonContainer>
        )}
      </Container>
    </AnimatePresence>
  );
};

export default Bottom;
