import React from "react";
import * as S from "./styles";

const index = () => {
  return (
    <div>
      <S.Intro>
        <img src="/intro_companym.png" alt="회사소개_모바일이미지"></img>
      </S.Intro>
      <S.IntroComment>
        <p>
          다양한 정보와 간편한 이용 경로로 구성된 다비교는 소비자들에게 스마트한
          소비를 돕고, 다양한 분야에서 더 나은 선택을 할 수 있도록 지원하고
          있습니다.
        </p>
      </S.IntroComment>
      <S.IntroTitle>
        <S.IntroWhy>왜?</S.IntroWhy>
        <S.IntroWhyDetail>다비교에 입점해야 하는가</S.IntroWhyDetail>
      </S.IntroTitle>
      <S.IntroContent>
        <S.Content>
          <img src="/intro_content1.png" alt="다양성_이미지"></img>
          <S.ContentTitle>다양성</S.ContentTitle>
          <S.ContentTitleDetail>
            다양한 분야의 업체들을 종합적으로 제공하여 사용자들에게 다양한
            선택지를 제공합니다. 이를 통해 더 많은 옵션을 비교하고 최적의 결정을
            내릴 수 있습니다.
          </S.ContentTitleDetail>
        </S.Content>
        <S.ContentCenter>
          <img src="/intro_content2.png" alt="신속한링크제공_이미지"></img>
          <S.ContentTitle>신속한 링크 제공</S.ContentTitle>
          <S.ContentTitleDetail>
            다비교는 각 업체의 목록을 클릭하면 즉시 사이트로 이동하는 링크를
            제공하여 사용자가 빠르게 필요한 정보에 접근할 수 있도록 도와줍니다.
          </S.ContentTitleDetail>
        </S.ContentCenter>
        <S.Content>
          <img src="/intro_content3.png" alt="업체입점활성화_이미지"></img>
          <S.ContentTitle>업체 입점 활성화</S.ContentTitle>
          <S.ContentTitleDetail>
            업체들을 활성화하기 위해 다비교는 적극적으로 플랫폼에 업체들을
            추가함으로써 최신 정보와 다양한 제품, 서비스를 제공합니다. 이는
            업체들이 더 많은 고객에게 소개될 기회를 제공합니다.
          </S.ContentTitleDetail>
        </S.Content>
        <S.IntroTitle>
          <S.IntroWhy>3개월 무료!</S.IntroWhy>
          <S.IntroWhyDetail>
            무료 서비스를 이용해보고 결정하세요
          </S.IntroWhyDetail>
        </S.IntroTitle>
        <S.FreeUse>
          <S.UseComment>
            <div>3개월동안 무료로 이용하며 방문자를 확인해 보세요</div>
          </S.UseComment>
          <S.UseComment>
            <div>이후 구독서비스로 합리적인 가격을 만나보세요</div>
          </S.UseComment>
        </S.FreeUse>
        <S.FreeArrowWrapper>
          <S.ArrowFirst></S.ArrowFirst>
          <S.ArrowSecond></S.ArrowSecond>
        </S.FreeArrowWrapper>
      </S.IntroContent>
      <S.FreeUseArrowDetail>
        <S.UseArrowDetailFirst>
          <div>최초 3개월 무료</div>
        </S.UseArrowDetailFirst>
        <S.UseArrowDetailSecond>
          <div>정기 이용권 할인</div>
        </S.UseArrowDetailSecond>
      </S.FreeUseArrowDetail>
      <S.IntroLastTitle>
        <div>소비자들과 연결되고 만나고 싶다면</div>
        <div>지금 입점 문의를 해보세요</div>
      </S.IntroLastTitle>
    </div>
  );
};

export default index;
