import React from "react";
import styled from "styled-components";
import { 탭_메뉴_TYPE } from "../../constants/defines";
const Container = styled.div`
  width: 100%;
  height: 100vh;
  background-image: url("/da_banner.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  overflow: hidden;
  touch-action: none;
`;

const Top = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Logo = styled.div`
  padding-top: 48px;
  img {
    width: 90px;
    height: 98px;
  }
`;

const LogoText = styled.div`
  padding-top: 20px;
  img {
    width: 136px;
    height: 44px;
  }
`;

const Comment = styled.div`
  color: #fff;
  font-size: 16px;
  line-height: 8px;
  font-weight: 700;
`;

const Bottom = styled.div`
  background-color: #fff;
  height: 45vh;
  position: absolute;
  width: 100%;
  bottom: 0;
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;
`;

const Title = styled.div`
  text-align: center;
  padding: 28px 0 14px;
  font-size: 22px;
  font-weight: 700;
`;

const Phone = styled.div`
  background-image: linear-gradient(91.55deg, #6db236 0%, #29d1b8 160.51%);
  width: calc(100% - 40px);
  margin: 0 auto;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 36px;
  font-weight: 700;
  gap: 20px;
  color: #fff;
  height: 80px;
  img {
    width: 40px;
    height: 40px;
  }
`;

const Service = styled.div`
  display: flex;

  align-items: center;
  justify-content: center;
  gap: 4px;
  padding: 20px 0 14px;
  color: #04b4bb;
  img {
    width: 20px;
    height: 20px;
  }
  font-weight: 700;
`;

const Items = styled.div`
  width: calc(100% - 40px);
  margin: 20px auto;
  height: 80px;

  display: flex;
  flex-wrap: wrap;
  border-radius: 12px;
  /* background-color: ${(props) => (props.active ? "#04B4BB" : "#E1F9FA")}; */
  background: #e1f9fa;
`;

const Item = styled.div`
  width: 33%;
  text-align: center;
  line-height: 40px;
  background-color: ${(props) => (props.active ? "#04B4BB" : "#E1F9FA")};
  color: ${(props) => (props.active ? "#fff" : "#04B4BB")};
  border-radius: 12px;
  font-size: 16px;
  font-weight: 500;
`;
const index = ({ 전화상담_신청 }) => {
  return (
    <Container onClick={전화상담_신청}>
      <Top>
        <Logo>
          <img src="/da_logo_only.png" alt="로고_이미지" />
        </Logo>
        <LogoText>
          <img src="/da_logo_textonly.png" alt="로고텍스트_이미지" />
        </LogoText>
        <Comment>
          <p>다양한 비교, 하나로 편리하게!</p>
          <p>최상의 선택을 위한 비교 서비스</p>
        </Comment>
      </Top>
      <Bottom>
        <Title>무료상담 대표번호 : 1644-7407</Title>
        <Phone>
          <img src="/phonem.png" alt="전화_이미지" />
          <p>전화상담</p>
        </Phone>
        <Service>
          <img src="/landing_service.png" alt="랜딩_서비스이미지"></img>
          현재 제공중인 비교서비스
        </Service>
        <Items>
          {탭_메뉴_TYPE.map(({ title, id }, index) => {
            return <Item>{title}</Item>;
          })}
        </Items>
      </Bottom>
    </Container>
  );
};

export default index;
