import styled from "styled-components";

export const Intro = styled.div`
  img {
    width: 100%;
    height: 226px;
  }
`;

export const IntroComment = styled.div`
  word-break: keep-all;
  padding-top: 40px;
  text-align: center;
`;

export const IntroTitle = styled.div`
  text-align: center;
  padding-top: 60px;
`;

export const IntroWhy = styled.div`
  font-size: 48px;
  font-weight: 700;
  padding-bottom: 12px;
`;
export const IntroWhyDetail = styled.div`
  font-size: 24px;
  font-weight: 700;
  padding-bottom: 40px;
  word-break: keep-all;
`;

export const IntroContent = styled.div``;

export const Content = styled.div`
  img {
    width: 60px;
    height: 60px;
    padding-bottom: 20px;
  }
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
  word-break: keep-all;
  text-align: center;
`;

export const ContentTitle = styled.div`
  color: #04b4bb;
  font-size: 24px;
  font-weight: 700;
  padding-bottom: 12px;
`;

export const ContentCenter = styled.div`
  img {
    width: 60px;
    height: 60px;
    padding-bottom: 20px;
  }
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 0 40px;
  border-top: 2px solid #e6e8ed;
  border-bottom: 2px solid #e6e8ed;
  text-align: center;
  word-break: keep-all;
`;

export const FreeUse = styled.div`
  display: flex;
  text-align: center;
`;
export const UseComment = styled.div`
  width: 50%;
  color: #6d7582;
  padding: 0 10px;
  word-break: keep-all;
`;

export const FreeArrowWrapper = styled.div`
  display: flex;
  text-align: center;
  height: 50px;
  border-right: 1px dashed #000;
`;

export const ArrowFirst = styled.div`
  width: 50%;
  border-left: 1px dashed #000;
  border-right: 1px dashed #000;
  border-bottom: 10px solid #04b4bb;
`;
export const ArrowSecond = styled.div`
  position: relative;
  display: inline-block;
  width: 50%;
  top: 80%;
  margin-right: 6px;
  height: 10px;
  background-color: #bec3cc;

  /* 삼각형 모양을 만들기 위한 가상 요소 설정 */
  &::before {
    content: "";
    position: absolute;
    top: -190%;
    right: -4%;
    width: 0;
    height: 0;

    border-style: solid;
    border-width: 12px 0 12px 15px;
    border-color: transparent transparent transparent #bec3cc;
    transform: translateY(50%);
  }
`;

export const FreeUseArrowDetail = styled.div`
  display: flex;
  text-align: center;
  padding: 20px 0 60px;
`;

export const UseArrowDetailFirst = styled.div`
  width: 50%;
  color: #04b4bb;
  font-size: 16px;
  font-weight: 700;
`;
export const UseArrowDetailSecond = styled.div`
  width: 50%;
  color: #6d7582;
  font-size: 16px;
  font-weight: 700;
`;

export const IntroLastTitle = styled.div`
  font-size: 18px;
  text-align: center;
  word-break: keep-all;
  padding-bottom: 80px;
`;

export const ContentTitleDetail = styled.div`
  color: #6d7582;
`;
